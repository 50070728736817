import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Typography, IconButton } from '@mui/material';

const Rank = styled.div`
  background-color: #FFFFFF; /* 배경색 변경 */
  border-radius: 10px;
  
  .rank {
    padding: 10px;
    width: 170px; /* 기본 너비 설정 */
  }

  .rank h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2em; /* 글씨 크기 줄임 */
    margin-bottom: 20px;
    border-bottom: 1px solid #DDDDDD; /* 표시줄 추가 */
    padding-bottom: 10px; /* 표시줄과 내용 사이의 간격 */
  }

  .rank ul {
    list-style: none;
    padding: 0;
    text-align: left; /* 목록 좌측 정렬 */
  }

  .rank li {
    display: flex; /* Flexbox를 사용하여 이미지와 텍스트를 수평으로 정렬 */
    align-items: center; /* 이미지와 텍스트를 수직 중앙 정렬 */
    margin: 10px 0;
    font-size: 0.8em; /* 글씨 크기 줄임 */
    width: 100%; /* 리스트 아이템 전체 너비 사용 */
  }

  .rank li img {
    margin-right: 10px; /* 이미지와 글씨 간격 추가 */
  }

  .rank li a {
    text-decoration: none; /* 클릭 효과 제거 */
    color: inherit; /* 클릭 효과 제거 */
    cursor: pointer; /* 포인터 커서로 변경 */
  }

  .rank li a:hover {
    text-decoration: underline; /* 호버 시 밑줄 */
  }

  .rank .clear {
    clear: both;
  }

  .rank a:focus {
    outline: none; /* 클릭 효과 제거 */
  }

  /* 768px 이하의 화면에서만 적용되는 스타일 */
  @media (max-width: 768px) {
    width: 50%; /* 전체 화면의 50% 사용 */
    .rank {
      width: 100%; /* Rank 내에서 전체 너비 사용 */
      box-sizing: border-box; /* padding을 포함한 너비 계산 */
    }
    .rank ul {
      display: flex;
      flex-direction: column; /* 리스트 아이템 세로 정렬 */
    }
    .rank li {
      width: 100%; /* 리스트 아이템이 전체 너비 사용 */
      justify-content: flex-start; /* 텍스트와 이미지를 왼쪽 정렬 */
    }
  }

  /* 425px 이하의 화면에서만 적용되는 스타일 */
  @media (max-width: 425px) {
    .rank {
      width: 100%; /* 전체 화면의 100% 사용 */
    }
    .rank h3 {
      flex-direction: column; /* Flexbox 방향을 세로로 변경 */
      align-items: flex-start; /* 왼쪽 정렬 */
    }
    .arrow-container {
      display: flex; /* Flexbox를 사용하여 화살표 버튼을 수평으로 배치 */
      justify-content: space-around; /* 가운데 정렬 */
      width: 100%; /* 전체 너비 사용 */
      margin-top: 10px; /* 위쪽 간격 추가 */
    }
  }
`;

export default function AutoBoxOfficeRanking() {
  const [visibleBox, setVisibleBox] = useState(1);
  const [movies, setMovies] = useState({ box1: [], box2: [] });

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const response = await fetch('/movieInfo/fetch-movie-info', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include' // 세션 기반 인증 정보를 포함
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const movieData = await response.json();
        const sortedMovies = movieData.sort((a, b) => a.rank - b.rank); // rank로 정렬
        const box1 = sortedMovies.slice(0, 5);
        const box2 = sortedMovies.slice(5, 10);
        setMovies({ box1, box2 });
      } catch (error) {
        console.error('Error fetching movie info:', error);
      }
    };

    fetchMovies();
  }, []);

  const showBox = (id) => {
    setVisibleBox(id);
  };

  return (
    <Rank>
      <div className="rank">
        <h3>
          <div style={{ textAlign: 'left' }}>
            <Typography variant="h6" style={{ color: '#1B1F23', fontWeight: 'bold' }}>
              박스오피스
            </Typography>
            <Typography variant="subtitle2" style={{ color: '#708090', fontSize: '10px' }}>
              예매율이 높은 TOP10
            </Typography>
          </div>
          <div className="arrow-container">
            <IconButton onClick={() => showBox(1)}>
              <KeyboardArrowLeft style={{ color: '#708090', fontSize: '16px' }} />
            </IconButton>
            <IconButton onClick={() => showBox(2)}>
              <KeyboardArrowRight  style={{ color: '#708090', fontSize: '16px' }} />
            </IconButton>
          </div>
        </h3>
        <ul>
          <div id="box1" style={{ display: visibleBox === 1 ? 'block' : 'none' }}>
            {movies.box1.map((movie, index) => (
              <li key={index} className={`no${index + 1}`}>
                <img src={`images/${index + 1}.png`} alt={`${index + 1}위`} title="" />
                <a href={`movie/info/details/${movie.movie_code}`}>{movie.title}</a>
              </li>
            ))}
          </div>
          <div id="box2" style={{ display: visibleBox === 2 ? 'block' : 'none' }}>
            {movies.box2.map((movie, index) => (
              <li key={index + 5} className={`no${index + 6}`}>
                <img src={`images/${index + 6}.png`} alt={`${index + 6}위`} title="" />
                <a href={`movie/info/details/${movie.movie_code}`}>{movie.title}</a>
              </li>
            ))}
          </div>
        </ul>
        <div className="clear"></div>
      </div>
    </Rank>
  );
}