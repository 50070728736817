import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useAuth } from "../../AuthContext";
import tiket from "../../res/movie.png";
import { getSessionFunc } from "../../api/func/userFunc";

const Container = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  max-width: 900px; /* 전체 컨테이너 너비를 조금 더 키움 */
  margin: 40px auto; /* 전체 박스에 여백 추가 */
  padding: 40px; /* 컨테이너 패딩 증가 */
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 40px; /* 박스 간의 간격을 넓게 */
  align-items: flex-start; /* 상단 정렬로 맞춤 */
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 0.7; /* 이미지 크기 증가 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto; /* 이미지 비율을 유지하면서 확대 */
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const Title = styled.h1`
  font-size: 32px; /* 제목 크기를 약간 더 키움 */
  color: #2c3e50;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
`;

const Form = styled.form`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 25px; /* 폼 요소 간의 간격을 늘림 */
  padding: 20px; /* 폼 자체에 패딩 추가 */
`;

const InputGroup = styled.div`
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #34495e;
  font-weight: 500;
`;

const Input = styled.input`
  width: calc(100% - 40px);
  padding: 12px 15px 12px 40px;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px; /* 버튼 패딩을 키움 */
  border: none;
  background-color: #2ecc71;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #27ae60;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 600px;
  height: 650px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background-color: transparent; /* 투명 배경색 설정 */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
`;
function TicketPurchase() {
  const { isLoggedIn, setIsLoggedIn, user, setUser } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    STOREID: "2400000058",
    CRYPTO_KEY: "4032EA1609A6C35FFAF15944571F625C",
    STORE_NAME: "주식회사 언체인",
    STORE_LOGO: "https://demo.paynuri.com/images/logo_shop.png",
    BUSINESS_NO: "5238103358",
    TX_USER_DEFINE: "언체인^457001-01-497662",
    TRAN_NO: "12345678910",
    PRODUCTTYPE: "2",
    TAX_FREE_CD: "00",
    TAX_AMT: "93",
    GOODS_NAME: "영화권",
    AMT: "1004",
    QUANTITY: "1",
    SALE_DATE: "",
    CUSTOMER_NAME: user?.name,
    NOTICE_URL: "",
    RETURN_URL: "https://cinema.skynet.re.kr/request/return_pay",
    COMPLETE_URL: "https://cinema.skynet.re.kr/pay_success",
    CANCEL_URL: "https://cinema.skynet.re.kr/pay_fail",
    STORE_URL: "cinema.skynet.re.kr",
    CUSTOMER_EMAIL: user?.email,
    CUSTOMER_TEL: user?.phone_number,
    CUSTOMER_IP: "110.15.204.218",
    ETC_DATA1: "테스트 데이터 여분1",
    ETC_DATA2: "테스트 데이터 여분2",
    ETC_DATA3: "테스트 데이터 여분3",
    TRAN_TYPE: "CARD",
  });

  const formRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    const today = new Date().toISOString().slice(2, 10).replace(/-/g, "");
    const tranNo = new Date().toISOString().replace(/[-:.TZ]/g, "");

    setFormData((prevData) => ({
      ...prevData,
      SALE_DATE: today,
      TRAN_NO: tranNo,
    }));
  }, []);

  const handlePayment = () => {
    const alertMessage = Object.entries(formData)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");
    console.log(alertMessage);

    if (window.confirm(`다음 정보를 확인해주세요:\n\n${alertMessage}`)) {
      setDialogOpen(true);
    }
  };

  const handleCloseModal = () => {
    setDialogOpen(false);
  };

  const handleIframeLoad = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeLocation = iframe.contentWindow.location.href;

      if (iframeLocation.includes("pay_success")) {
        alert("결제 성공!");
        window.location.href = formData.COMPLETE_URL;
        setDialogOpen(false);
      } else if (iframeLocation.includes("pay_fail")) {
        alert("결제 실패!");
        window.location.href = formData.CANCEL_URL;
        setDialogOpen(false);
      }
    }
  };
  useEffect(() => {
    if (dialogOpen && formRef.current) {
      formRef.current.submit();
    }
  }, [dialogOpen]);

  useEffect(() => {
    // console.log("HP");
    const storedUser = JSON.parse(sessionStorage.getItem("user"));

    // id로 session 데이터 가져옴
    const getSession = async (phone_number) => {
      const returnSession = await getSessionFunc(phone_number);
      return returnSession;
    };

    //로그인 상태 판별
    const isLoginFunc = async (storedUser) => {
      // console.log(`=== start is login ===`);
      // 세션 스토리지의 id를 가져온다.
      if (
        storedUser !== null &&
        (storedUser.phone_number !== null ||
          storedUser.phone_number !== undefined)
      ) {
        try {
          // console.log("session 값이 있음");
          // console.log(`storedUser.phone_number : ${storedUser.phone_number}`);
          const returnSession = await getSession(storedUser.phone_number);
          // console.log(returnSession);
          // console.log(JSON.stringify(returnSession));
          // console.log(`code : ${returnSession.code} `);
          if (returnSession.code == 200 && returnSession.result !== 0) {
            // 서버 세션이 남아있을 경우
            // console.log("서버 session true");
            setIsLoggedIn(true);
            setUser(storedUser);
          } else {
            // 서버 Redis에 세션이 없을 경우
            // console.log("서버 session false");
            setIsLoggedIn(false);
            setUser(null);
            sessionStorage.removeItem("user");
          }
        } catch (e) {
          console.log("로그인 판별 에러 : e");
        }
      } else {
        // 세션 스토리지 아이디가 없을 경우
        // console.log("session false");
        setIsLoggedIn(false);
        setUser(null);
      }
      // console.log(`=== end is login ===`);
    };
    isLoginFunc(storedUser);
  }, [isLoggedIn]);

  // 로그인 상태에 따른 UI 처리
  return (
    <Container>
      {isLoggedIn ? (
        <>
          <ImageContainer>
            <Title>영화 티켓 구매</Title>
            <Image src={tiket} alt="Movie Ticket" />
          </ImageContainer>
          <FormContainer>
            <Form>
              <InputGroup>
                <Label htmlFor="CUSTOMER_NAME">이름</Label>
                <Input
                  type="text"
                  id="CUSTOMER_NAME"
                  name="CUSTOMER_NAME"
                  value={formData.CUSTOMER_NAME}
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="CUSTOMER_EMAIL">이메일</Label>
                <Input
                  type="email"
                  id="CUSTOMER_EMAIL"
                  name="CUSTOMER_EMAIL"
                  value={formData.CUSTOMER_EMAIL}
                  readOnly
                />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="CUSTOMER_TEL">전화번호</Label>
                <Input
                  type="tel"
                  id="CUSTOMER_TEL"
                  name="CUSTOMER_TEL"
                  value={formData.CUSTOMER_TEL}
                  readOnly
                />
              </InputGroup>
            </Form>
            <Button type="button" onClick={handlePayment}>
              결제하기
            </Button>
          </FormContainer>
          {dialogOpen && (
            <ModalOverlay>
              <ModalContent>
                <iframe
                  name="iframe_payment"
                  id="iframe_payment"
                  src="about:blank"
                  width="670"
                  height="800"
                  scrolling="no"
                  frameBorder="0"
                  ref={iframeRef}
                  onLoad={handleIframeLoad}
                ></iframe>
              </ModalContent>
            </ModalOverlay>
          )}
          <form
            action="https://pg.paynuri.com/paymentgateway/pc/reqPay.do"
            method="post"
            name="frmPayment"
            id="frmPayment"
            target="iframe_payment"
            acceptCharset="EUC-KR"
            ref={formRef}
            style={{ display: "none" }}
          >
            {Object.entries(formData).map(([key, value]) => (
              <input key={key} type="hidden" name={key} value={value} />
            ))}
          </form>
        </>
      ) : (
        <Title>로그인 후 이용하세요</Title>
      )}
    </Container>
  );
}

export default TicketPurchase;
