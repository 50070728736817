import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // AuthContext 가져오기
import Cookies from "js-cookie";
import { setSessionFunc } from "../api/func/userFunc";

function AutoLogin() {
  const [message, setMessage] = useState("로그인 중입니다...");
  const navigate = useNavigate();
  const { login } = useAuth(); // useAuth로 login 함수 가져오기

  useEffect(() => {
    // URL에서 파라미터 추출
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone_number");
    const pass = urlParams.get("password");
    const redirectToMyPage = urlParams.get("redirectToMyPage"); // 개인정보 페이지로 리디렉트 여부 확인
    alert(phone + "자동로그인");
    if (phone && pass) {
      autoLogin(phone, pass, redirectToMyPage); // 파라미터 추가
    } else {
      setMessage("로그인 정보가 없습니다. 다시 시도해주세요.");
    }
  }, []); // 컴포넌트가 마운트될 때 한 번 실행

  const validateForm = (phoneNumber, password) => {
    if (!phoneNumber) {
      setMessage("전화번호를 입력해주세요.");
      return false;
    }
    if (!password) {
      setMessage("비밀번호를 입력해주세요.");
      return false;
    }
    setMessage("");
    return true;
  };

  const autoLogin = async (phoneNumber, password, redirectToMyPage) => {
    if (validateForm(phoneNumber, password)) {
      try {
        const response = await fetch(`/request/login`, {
          credentials: "include",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_number: phoneNumber,
            password: password,
          }),
        });

        if (response.ok) {
          const userData = await response.json();
          // console.log(userData);
          alert(`${userData.name}님, 로그인에 성공하였습니다!`);
          login(userData); // AuthContext의 login 호출
          const setSession = await setSessionFunc(userData);
          if (setSession.code == 200) {
            // 서버 세션에 데이터 저장 완료.
            // console.log("서버 세션에 데이터 저장 완료.");
            // 로그인 성공 후 개인정보 페이지로 리디렉트 여부 확인
            // console.log(`redirectToMyPage : ${redirectToMyPage}`);
            if (redirectToMyPage === "true") {
              navigate("/mypage/user/edit"); // 개인정보 수정 페이지로 리디렉트
            } else {
              navigate("/"); // 기본 홈 페이지로 리디렉트
            }
          } else {
            // 서버 세션에 데이터 저장 실패.
            setMessage("서버 확인이 필요합니다.");
          }
        } else {
          if (response.status === 401) {
            setMessage("전화번호 또는 비밀번호가 잘못되었습니다.");
          } else {
            setMessage("로그인에 실패하였습니다. 다시 시도해주세요.");
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage("로그인 요청 중 문제가 발생했습니다.");
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <p>{message}</p>
    </div>
  );
}

export default AutoLogin;
