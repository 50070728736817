import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { openDB } from 'idb';

// 스타일 정의
const BestTopWrapper = styled.div`
  border: 1px solid #DDDDDD;
  .best_top {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    font-weight: bold;
    font-family: NanumGothic;
    width: 550px; /* 슬라이드 컴포넌트의 너비 설정 */
    margin: 0 auto; /* 가운데 정렬 */
  }

  .best_top_title_container {
    text-align: left; /* 좌측 정렬 */
    position: relative;
    z-index: 1;
  }

  .best_top_title {
    color: #E09C13;
    font-family: NanumGothic;
  }

  .best_top_subtitle {
    color: #000;
    font-family: NanumGothic;
  }

  .slider-container {
    overflow: hidden;
    width: 100%;
    box-sizing: border-box; /* 컨테이너 크기 설정 */
    position: relative;
  }

  .bxslider {
    display: flex;
    margin: 0; /* 슬라이더 여백 초기화 */
    padding: 0; /* 슬라이더 패딩 초기화 */
    transition: transform 0.5s ease-in-out; /* 전환 속도 빠르게 설정 */
  }

  .postercommon {
    margin-top: 10px;
    width: 100%; /* 포스터 너비를 100%로 설정하여 컨테이너에 맞춤 */
    height: auto; /* 비율에 맞게 높이 설정 */
    display: block;
    border-radius: 10px; /* 포스터 모서리 둥글게 설정 */
    transition: transform 0.3s ease-in-out; /* hover 애니메이션 설정 */
    position: relative;
    z-index: 0;
  }

  .postercommon:hover {
    transform: scale(1.1); /* hover 시 이미지 확대 */
    z-index: 2; /* hover 시 z-index를 높여 제목 위로 올림 */
  }

  .poster_title {
    color: #333;
    display: block;
    overflow: hidden;
    height: 50px;
    text-align: left;
    font-weight: normal; /* 볼드 해제 */
    font-size: 0.8rem; /* 글씨 크기 줄임 */
  }

  li {
    list-style: none;
    margin: 0; /* 리스트 아이템 여백 초기화 */
    padding: 0 5px; /* 리스트 아이템 양쪽 패딩 설정 */
    flex: 0 0 25%; /* 4개씩 보이게 하기 위해 각 아이템의 너비를 25%로 설정 */
    box-sizing: border-box; /* 패딩을 포함한 너비 설정 */
  }

  @media (max-width: 580px) {
    .slider-container {
      perspective: 1000px; /* 3D 회전을 위한 원근감 설정 */
      height: 250px; /* 작은 화면에서 슬라이더 높이 조정 */
    }

    .bxslider {
      display: block;
      transform-style: preserve-3d;
      transform-origin: center;
      position: relative;
      height: 100%;
    }

    li {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
        ${({ currentSlide, index, movies }) =>
          `rotateY(${(360 / movies.length) * (index - currentSlide)}deg) translateZ(${window.innerWidth / 3}px)`};
      transition: transform 0.5s ease-in-out; /* 전환 속도 빠르게 설정 */
      width: ${({ width }) => width * 0.25}px; /* 포스터 크기를 화면 너비에 비례하여 조정 */
      height: auto;
    }

    .postercommon {
      width: ${({ width }) => width * 0.25}px;
      height: ${({ width }) => width * 0.33}px; /* 비율에 맞춰 포스터 높이 조정 */
      object-fit: cover;
      margin: 0 auto;
      border: 1px solid #DDDDDD;
    }
  }

  a {
    text-decoration: none;
    display: block;
    position: relative;
    z-index: 1;
  }
`;

// IndexedDB 초기화 및 업그레이드
const initDB = async () => {
  return openDB('bestTop8DB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('movies')) {
        db.createObjectStore('movies', { keyPath: 'movie_code' });
      }
    },
  });
};

// 영화 정보를 저장하는 함수
const storeMovieData = async (db, movies) => {
  const tx = db.transaction('movies', 'readwrite');
  const store = tx.objectStore('movies');

  // 이전 데이터를 모두 제거
  await store.clear();

  // 정렬된 영화 데이터를 저장
  for (const movie of movies) {
    await store.put(movie);
  }

  await tx.done;
};

// 영화 정보를 가져오는 함수
const getStoredMovies = async (db) => {
  const tx = db.transaction('movies', 'readonly');
  const store = tx.objectStore('movies');
  const movies = await store.getAll();
  await tx.done;

  // 가져온 데이터를 rank에 따라 정렬
  return movies.sort((a, b) => a.rank - b.rank);
};

export default function AutoBestTop8Card() {
  const [movies, setMovies] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 580);

  // 드래그 시작점 및 현재 위치 저장
  const startXRef = useRef(0);
  const isDraggingRef = useRef(false);

  useEffect(() => {
    const fetchMovies = async () => {
      const db = await initDB();
      const today = new Date().toISOString().split('T')[0]; // 오늘 날짜

      // IndexedDB에서 영화 데이터를 먼저 가져오기
      const storedMovies = await getStoredMovies(db);

      if (storedMovies.length > 0 && storedMovies[0].created_at === today) {
        // IndexedDB에 데이터가 있고, created_at이 오늘인 경우
        setMovies(storedMovies);
      } else {
        // API에서 영화 데이터를 가져옴
        try {
          const response = await fetch(
            '/movieInfo/fetch-movie-info',
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
              credentials: 'include', // 세션 기반 인증 정보를 포함
            }
          );
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const movieData = await response.json();

          // 영화 데이터를 rank에 따라 정렬
          const sortedMovies = movieData
            .sort((a, b) => a.rank - b.rank)
            .slice(0, 8); // 상위 8개 선택

          if (sortedMovies[0].created_at === today) {
            console.log('Fetched movies are up-to-date:', sortedMovies);
            setMovies(sortedMovies);
            // 정렬된 데이터를 IndexedDB에 저장
            await storeMovieData(db, sortedMovies);
          } else {
            console.log('Fetched movies are outdated. Updating...');
            await storeMovieData(db, sortedMovies); // 새로운 데이터 저장
            setMovies(sortedMovies);
          }
        } catch (error) {
          console.error('Error fetching movie info:', error);
        }
      }
    };

    fetchMovies();
  }, []); // Fetch movies once when the component mounts

  useEffect(() => {
    // 화면 크기 변경을 감지하여 3D 슬라이드를 적용
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 580);
      setCurrentSlide(0); // 화면 크기 변경 시 슬라이드 상태 초기화
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Add empty dependency array to ensure event listener is added once

  useEffect(() => {
    // 자동 슬라이드 전환
    const interval = setInterval(() => {
      if (isSmallScreen) {
        // 3D 회전 슬라이드 (5초 간격)
        setCurrentSlide((prev) => (prev + 1) % movies.length);
      } else {
        // 기본 슬라이드 (15초 간격, 3번 전환 후 처음으로 돌아감)
        setCurrentSlide((prev) => (prev + 1) % 4);
      }
    }, isSmallScreen ? 5000 : 15000);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 타이머 정리
  }, [movies.length, isSmallScreen]); // Depend on movies.length and isSmallScreen

  // 마우스 및 터치 다운 이벤트 처리
  const handleStart = (event) => {
    isDraggingRef.current = true;
    startXRef.current = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX;
  };

  // 마우스 및 터치 이동 이벤트 처리
  const handleMove = (event) => {
    if (!isDraggingRef.current) return;

    const currentX = event.type === 'mousemove' ? event.clientX : event.touches[0].clientX;
    const distance = currentX - startXRef.current;

    // 드래그한 거리에 따라 슬라이드를 회전시킴
    if (Math.abs(distance) > 20) { // 드래그 거리 10px 이상일 때만 작동
      setCurrentSlide(
        (prev) => (prev - Math.sign(distance) + movies.length) % movies.length
      );
      startXRef.current = currentX; // 현재 위치를 새로운 시작점으로 설정
    }
  };

  // 마우스 및 터치 업 이벤트 처리
  const handleEnd = () => {
    isDraggingRef.current = false;
  };

  // 이미지 드래그 방지 핸들러
  const handleDragStart = (event) => {
    event.preventDefault();
  };

  // 3D 회전 슬라이드를 렌더링하는 함수
  const render3DRotationSlider = () => {
    return (
      <ul
        className="bxslider"
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd} // 마우스가 슬라이더 영역을 떠나면 드래그 중지
        onTouchStart={handleStart} // 모바일 터치 시작
        onTouchMove={handleMove} // 모바일 터치 이동
        onTouchEnd={handleEnd} // 모바일 터치 종료
      >
        {movies.map((poster, index) => (
          <li
            key={poster.movie_code}
            style={{
              transform: `translateX(${
                (window.innerWidth - 580) / 2
              }px) translate(-50%, -50%) rotateY(${
                (360 / movies.length) * (index - currentSlide)
              }deg) translateZ(${window.innerWidth / 3}px)`,
            }}
          >
            <a href={`movie/info/details/${poster.movie_code}`}>
              <img
                src={poster.poster}
                alt="포스터"
                className="postercommon"
                onDragStart={handleDragStart} // 이미지 드래그 방지
              />
            </a>
            {/* <span className="poster_title">{poster.title}</span> */}
          </li>
        ))}
      </ul>
    );
  };

  // 581px 이상에서의 기본 슬라이드
  const renderBasicSlider = () => {
    return (
      <ul
        className="bxslider"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {movies.map((poster) => (
          <li key={poster.movie_code}>
            <a href={`movie/info/details/${poster.movie_code}`}>
              <img
                src={poster.poster}
                alt="포스터"
                className="postercommon"
                onDragStart={handleDragStart} // 이미지 드래그 방지
              />
            </a>
            <span className="poster_title">{poster.title}</span>
          </li>
        ))}
        {movies.map((poster) => (
          <li key={`repeat-${poster.movie_code}`}>
            <a href={`movie/info/details/${poster.movie_code}`}>
              <img
                src={poster.poster}
                alt="포스터"
                className="postercommon"
                onDragStart={handleDragStart} // 이미지 드래그 방지
              />
            </a>
            <span className="poster_title">{poster.title}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <BestTopWrapper
      currentSlide={currentSlide}
      movies={movies}
      width={window.innerWidth}
    >
      <div className="best_top">
        <div className="best_top_title_container">
          <span className="best_top_title">BEST</span>
          <span className="best_top_subtitle"> TOP8</span>
        </div>
        <div className="slider-container">
          {movies.length > 0 ? (
            isSmallScreen ? render3DRotationSlider() : renderBasicSlider()
          ) : (
            <p>데이터를 불러오는 중입니다...</p>
          )}
        </div>
      </div>
    </BestTopWrapper>
  );
}
