import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../AuthContext';

const StickerContainerWrapper = styled.div`
  position: ${(props) => (props.isMobile ? 'relative' : 'fixed')};
  right: ${(props) => (props.isMobile ? '0' : `${props.rightOffset}px`)};
  top: ${(props) => (props.isMobile ? 'auto' : '150px')};
  bottom: ${(props) => (props.isMobile ? '10px' : 'auto')};
  width: ${(props) => (props.isMobile ? '100px' : '100px')}; /* 모바일과 데스크탑 너비 조정 */
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'row' : 'column')}; /* 가로/세로 정렬 */
  justify-content: ${(props) => (props.isMobile ? 'space-between' : 'flex-start')};
  align-items: center; /* 세로 중앙 정렬 */
  z-index: 1;
  transition: right 0.3s ease, top 0.3s ease, bottom 0.3s ease, width 0.3s ease;
  cursor: pointer;
`;

const StickerItem = styled.div`
  margin-bottom: ${(props) => (props.isMobile ? '0' : '10px')}; /* 모바일에서는 간격 제거 */
  width: ${(props) => (props.isMobile ? '50px' : '100%')}; /* 이미지 너비 조정 */
  flex-shrink: 0; /* 크기 축소 방지 */
`;

export default function StickerContainer() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [rightOffset, setRightOffset] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const calculateRightOffset = () => {
      const windowWidth = window.innerWidth;
      const mainWidth = Math.min(windowWidth, 1200);
      const offset = (windowWidth - mainWidth) / 2 - 30;
      setRightOffset(offset);
      setIsMobile(windowWidth <= 1300);
    };

    // 초기 실행
    calculateRightOffset();

    // 리사이즈 시 이벤트 리스너 등록
    window.addEventListener('resize', calculateRightOffset);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener('resize', calculateRightOffset);
    };
  }, []);

  const openPopup = () => {
    if (user) {
      window.open(
        '/mypage/coupon/registercoupon?issetcoupon=false',
        'Coupon Register',
        'width=600,height=600,top=100,left=100'
      );
    } else {
      navigate('/user/user/login');
    }
  };

  const handleBannerClick = (e) => {
    e.preventDefault();
    if (user) {
      navigate('/cscenter/board/17');
    } else {
      navigate('/user/user/login');
    }
  };

  return (
    <StickerContainerWrapper rightOffset={rightOffset} isMobile={isMobile}>
      <StickerItem isMobile={isMobile}>
        <img
          src='/images/right_1to1.png'
          alt='1to1_banner'
          onClick={handleBannerClick}
          style={{ width: isMobile ? '40px' : '100%', height: 'auto' }} // 이미지 크기 조정
        />
      </StickerItem>
      <StickerItem isMobile={isMobile}>
        <img
          src='/images/coupon_left_yellow.png'
          alt='coupon_left_banner'
          onClick={openPopup}
          style={{ width: isMobile ? '40px' : '100%', height: 'auto' }} // 이미지 크기 조정
        />
      </StickerItem>
    </StickerContainerWrapper>
  );
}
