import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const LoginCheck = styled.div`
  text-align: center;
  margin-bottom: 20px;

  p {
    color: #FF661D;
    font-size: 1rem;
  }
`;

const StyledLoginButton = styled(Link)`
  display: inline-block;
  background-color: #FFC107;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
  text-decoration: none; /* 링크 스타일 제거 */

  &:hover {
    background-color: #0056b3;
  }
`;

const Terms = styled.div`
  width: 100%;
  margin-bottom: 20px;

  textarea {
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    margin-bottom: 10px;
    resize: none;
  }

  label {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    color: #3985D1;

    input {
      margin-right: 5px;
    }
  }
`;

const UserInfo = styled.div`
  width: 100%;
  margin-bottom: 20px;

  h2 {
    color: #FF661D;
    border-bottom: 2px solid #CC9933;
    padding-bottom: 5px;
    text-align: center; /* 제목을 중앙 정렬 */
  }

  form {
    display: flex;
    flex-direction: column; /* 세로 정렬 */
    align-items: center; /* 폼 요소들을 중앙으로 정렬 */
  }

  .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    justify-content: center;
    position: relative; /* Position relative for absolute button positioning */
    max-width: 300px;
  }

  label {
    width: 80px;
    font-size: 0.8rem;
    color: #333;
  }

  input {
    flex: 1;
    height: 40px;
    padding: 5px 50px 5px 10px; /* Adjust padding for button space */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    max-width: 300px;
    box-sizing: border-box; /* Include padding and border in width */
  }

  .verification-button {
    position: absolute; /* Position button inside input */
    right: 10px; /* Adjust right position for input integration */
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    background-color: #FFC107;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7rem;
    cursor: pointer;
    padding: 0 10px;
    display: none; /* Hide button initially */

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const RegisterButton = styled.button`
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#FFC107")};
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-size: 1em;
  margin-top: 20px; /* Add some space above the button */
  margin-bottom: 20px;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "#ccc" : "#0056b3")};
  }
`;

const Message = styled.p`
  color: red;
  text-align: center;
`;

function RegisterForm() {
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [newPassword, setNewPassword] = useState(""); // 비밀번호 필드 추가
  const [confirmNewPassword, setConfirmPassword] = useState(""); // 비밀번호 확인 필드 추가
  const [message, setMessage] = useState("");
  const [useConditions, setUseConditions] = useState("");
  const [personalInfo, setPersonalInfo] = useState("");

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPrivacyAgreed, setIsPrivacyAgreed] = useState(false);


  const navigate = useNavigate();

  useEffect(() => {
    fetch("/registerText/useinfo.txt")
      .then((response) => response.text())
      .then((data) => setUseConditions(data))
      .catch((error) => console.error("Error fetching useConditions:", error));

    fetch("/registerText/privacy.txt")
      .then((response) => response.text())
      .then((data) => setPersonalInfo(data))
      .catch((error) => console.error("Error fetching personalInfo:", error));
  }, []);

  const validateForm = () => {
    if (!username || username.length === 0) {
      setMessage("사용자 이름을 입력해주세요.");
      return false;
    }
  
    if (!phone || phone.length === 0) {
      setMessage("전화번호를 입력해주세요.");
      return false;
    }
  
    if (!newPassword || newPassword.length === 0) {
      setMessage("비밀번호를 입력해주세요.");
      return false;
    }
  
    if (!confirmNewPassword || confirmNewPassword.length === 0) {
      setMessage("비밀번호 확인을 입력해주세요.");
      return false;
    }
  
    if (newPassword !== confirmNewPassword) {
      setMessage("비밀번호와 비밀번호 확인이 일치하지 않습니다.");
      return false;
    }
  
    if (!isTermsAgreed) {
      setMessage("이용 약관에 동의해주세요.");
      return false;
    }
  
    if (!isPrivacyAgreed) {
      setMessage("개인정보 처리 방침에 동의해주세요.");
      return false;
    }
  
    // 모든 조건이 만족되면 true 반환
    return true;
  };

  const handleSubmitRegister = async (event) => {
    event.preventDefault();
  
    // 유효성 검사 실패 시 서버 요청 중단
    if (!validateForm()) return;
  
    try {
      const response = await fetch("/request/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: username,
          phone_number: phone,
          new_password: newPassword,
          confirm_new_password: confirmNewPassword,
        }),
      });
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
  
      alert(`${username}님, 회원가입이 성공적으로 완료되었습니다.`);
      navigate("/user/user/login");
    } catch (error) {
      console.error("회원가입 실패: ", error.message);
      setMessage(error.message);
    }
  };

  return (
    <Container>
      <LoginCheck>
        <p>사용자정보를 입력 하신적이 있으면 로그인을 해주세요.</p>
        <StyledLoginButton to="/user/user/login">로그인하기</StyledLoginButton>
      </LoginCheck>
      <Terms>
        <textarea readOnly value={useConditions}></textarea>
        <label>
          <input
           type="checkbox"
           required
           checked={isTermsAgreed}
           onChange={() => setIsTermsAgreed(!isTermsAgreed)} 
           />{" "}
           이용약관 동의
        </label>
      </Terms>
      <Terms>
        <textarea readOnly value={personalInfo}></textarea>
        <label>
          <input
           type="checkbox"
            required 
            checked={isPrivacyAgreed}
            onChange={() => setIsPrivacyAgreed(!isPrivacyAgreed)}
            />{" "}
           개인정보 수집 및 이용 동의
        </label>
      </Terms>
      <UserInfo>
        <h2>사용자정보 입력하기</h2>
        <form onSubmit={handleSubmitRegister}>
          <Message>{message}</Message>
          <div className="input-group">
            <label>이름</label>
            <input
              type="text"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>휴대폰번호</label>
            <input
              type="text"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <button
              type="button"
              className="verification-button"
              style={{ display: phone ? "block" : "none" }}
            >
              인증
            </button>
          </div>
          <div className="input-group">
            <label>비밀번호</label>
            <input
              type="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label>비밀번호 확인</label>
            <input
              type="password"
              required
              value={confirmNewPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <RegisterButton
           type="submit"
           disabled={!isTermsAgreed || !isPrivacyAgreed}
           >
            회원가입
          </RegisterButton>
        </form>
      </UserInfo>
    </Container>
  );
}

export default RegisterForm;
