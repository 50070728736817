import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const SubNavigatorContainer = styled.div`
  height: auto;
  width: 10rem;
  background-color: #F2F2F5; /* 서브 네비게이터 배경색 */
`;

const SubNavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 10px;
  list-style: none;
  text-align: left; /* 텍스트를 왼쪽으로 정렬 */
`;

const SubNavItem = styled.li`
  padding: 10px;
  text-align: left; /* 텍스트를 왼쪽으로 정렬 */
`;

const SubCustomerService = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
`;

const SubPhoneIcon = styled.img`
  width: 100%; /* Ensure the image fits within the container */
  max-width: 160px; /* Set a maximum width */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
`;

const SubPhoneNumberOverlay = styled.div`
  font-size: 20px; /* Adjust font size */
  color: #CC9933;
  white-space: nowrap; /* Prevent line break */
  padding: 10px 0; /* Add padding above and below the text */
  border-top: 1px solid #DDDDDD; /* Add top border */
  border-bottom: 1px solid #DDDDDD; /* Add bottom border */
  width: 100%; /* Make sure the border spans the full width */
  text-align: center; /* Center text horizontally */
  background-color: white; /* Ensure the background color */
`;

const StyledNavLink = styled(NavLink)`
  color: #3B5998; /* 기본 글자색 */
  text-decoration: none;
  font-weight: bold;
  text-align: left; /* 텍스트를 왼쪽으로 정렬 */

  &:hover {
    color: #EDAD00; /* 호버했을 때 글자색 */
  }
`;

export default function SubNavigator({ links, onLinkClick }) {
  return (
    <SubNavigatorContainer>
      <SubNavList className="rightNav">
        {links.map((link, index) => (
          <SubNavItem key={index}>
            <StyledNavLink to={link.path} onClick={onLinkClick}>
              {link.label}
            </StyledNavLink>
          </SubNavItem>
        ))}
      </SubNavList>
      <SubCustomerService>
        <SubPhoneIcon src="/images/left_cscenter.png" alt="고객센터" />
        <SubPhoneNumberOverlay>1522-0391</SubPhoneNumberOverlay>
      </SubCustomerService>
    </SubNavigatorContainer>
  );
}
