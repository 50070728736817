import React, { useState, useRef, useEffect, useMemo } from 'react';
import ImageResize from "quill-image-resize-module-react";
import ReactQuill, { Quill } from 'react-quill'; // ES6
import quillTable from 'quill-table';

import 'react-quill/dist/quill.snow.css';
import './style.css';
import styled from 'styled-components';

window.Quill = Quill;
let Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '14px', '16px', '18px', '20px']; // 사용 가능한 폰트 크기

Quill.register(Size, true);
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/imageResize", ImageResize);
Quill.register('modules/table', quillTable.TableModule);

const MainContainer = styled.main`
  margin-top: -10px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Input = styled.input`
  width: ${props => props.width || '100%'};
  padding: 10px;
  border-width: thin;
  border-color: silver;
`;

const Article = styled.article`
  text-align: left;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5rem;
  align-items: center;
  font-size: 1.3rem;
`;

function HtmlEditor({ Submit, RequestFrom, SelectedCategory, IsPublic }) {
  const quillRef = useRef();
  const [글제목, set글제목] = useState(RequestFrom?.title || '');
  const [설교제목, set설교제목] = useState(RequestFrom?.sermon_title || '');
  const [성경구절, set성경구절] = useState(RequestFrom?.bible_verse || '');
  const [전송양식, set전송양식] = useState({
    item_id: RequestFrom?.item_id || '',
    user_name: RequestFrom?.author || '',
    content: RequestFrom?.content || '',
    create_date: RequestFrom?.create_date || '',
    category: SelectedCategory || '',
    is_public: IsPublic || '',
    sermon_title: RequestFrom?.sermon_title || '',
    bible_verse: RequestFrom?.bible_verse || ''
  });
  const [일자, set일자] = useState(RequestFrom?.create_date || '');

  useEffect(() => {
    const quill = quillRef.current.getEditor();
    setupDragAndDrop(quill);
    set전송양식({
      item_id: RequestFrom.item_id,
      user_name: RequestFrom.author,
      content: RequestFrom.content,
      create_date: RequestFrom.create_date,
      category: SelectedCategory,
      is_public: IsPublic,
      sermon_title: RequestFrom.sermon_title,
      bible_verse: RequestFrom.bible_verse
    });
    set글제목(RequestFrom.title);
    set설교제목(RequestFrom.sermon_title);
    set성경구절(RequestFrom.bible_verse);
    set일자(RequestFrom.create_date);
  }, [RequestFrom, SelectedCategory, IsPublic]);

  const OnTextTitleChanged = (ev) => {
    set전송양식({ ...전송양식, title: ev.target.value });
    set글제목(ev.target.value);
  };

  const OnSermonTitleChanged = (ev) => {
    set전송양식({ ...전송양식, sermon_title: ev.target.value });
    set설교제목(ev.target.value);
  };

  const OnBibleVerseChanged = (ev) => {
    set전송양식({ ...전송양식, bible_verse: ev.target.value });
    set성경구절(ev.target.value);
  };

  const handleDateChange = (ev) => {
    set전송양식({ ...전송양식, create_date: ev.target.value });
    set일자(ev.target.value);
  };

  const EditChange = (content, delta, source, editor) => {
    var Text = editor.getText();
    set전송양식({ ...전송양식, content: Text });
  };

  const Validate = () => {
    if (글제목 !== '' && 전송양식.content !== '') {
      Submit(전송양식);
    } else {
      alert('제목과 내용은 필수로 입력해야 합니다.');
    }
  };

  const imageHandler = () => {
    const quill = quillRef.current.getEditor();
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      const formData = new FormData();
      const encodedFileName = encodeURIComponent(file.name);
      formData.append("img", file, encodedFileName);
      formData.append("userId", 'scribe');

      try {
        const response = await fetch(
          //'https://file.antsnest.co.kr:3000/upload/AttachFileUpload',
          'https://file.antsnest.co.kr:3400/upload/Image',
          {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        const range = quill.getSelection()?.index;
        if (typeof (range) !== "number") return;

        const url =
          //'https://file.antsnest.co.kr:2550/antsnest/AttachFile/'
          'https://file.antsnest.co.kr:3400/web_images/'
          + data.filename;
        quill.insertEmbed(range.index, 'image', url, Quill.sources.USER);
        quill.formatText(range.index, 1, 'align', 'center');

        quill.setSelection(range.index + 1, Quill.sources.SILENT);

        console.log('사진업로드 완료:' + JSON.stringify(data));
      } catch (err) {
        console.log('업로드 오류:', err.message);
      }
    };
  }

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'align': [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ 'color': [] }, { 'background': [] }],
        ['bold', 'italic', 'underline', 'clean'],
        [{ 'image': 'imageResize' }, 'code-block'],
        [{ 'table': ['insertTable', 'insertRowAbove', 'insertRowBelow', 'insertColumnLeft', 'insertColumnRight', 'deleteRow', 'deleteColumn', 'deleteTable'] }]
      ],
      handlers: {
        'image': imageHandler,
        'insertTable': () => handleTableActions('insertTable'),
        'insertRowAbove': () => handleTableActions('insertRowAbove'),
        'insertRowBelow': () => handleTableActions('insertRowBelow'),
        'insertColumnLeft': () => handleTableActions('insertColumnLeft'),
        'insertColumnRight': () => handleTableActions('insertColumnRight'),
        'deleteRow': () => handleTableActions('deleteRow'),
        'deleteColumn': () => handleTableActions('deleteColumn'),
        'deleteTable': () => handleTableActions('deleteTable')
      },
    },
    'table': true,
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize", "Toolbar"]
    },
  }), []);

  function setupDragAndDrop(quill) {
    const editorContainer = quill.container;

    editorContainer.addEventListener('dragover', function (e) {
      e.preventDefault();
    });

    editorContainer.addEventListener('drop', function (e) {
      e.preventDefault();

      if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file.type.startsWith('image/')) {
          insertImageFromFile(file, quill);
        }
      }
    });
  }

  function insertImageFromFile(file, quill) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const range = quill.getSelection();
      if (range) {
        quill.insertEmbed(range.index, 'image', reader.result);
        quill.setSelection(range.index + 1);
      }
    });
    reader.readAsDataURL(file);
  }

  function handleTableActions(action) {
      const quill = quillRef.current.getEditor();
      const tableMdl = quill.getModule('table');
      switch (action) {
        case 'insertTable':
          tableMdl.insertTable(3, 3);
          break;
        case 'insertRowAbove':
          tableMdl.insertRowAbove();
          break;
        case 'insertRowBelow':
          tableMdl.insertRowBelow();
          break;
        case 'insertColumnLeft':
          tableMdl.insertColumnLeft();
          break;
        case 'insertColumnRight':
          tableMdl.insertColumnRight();
          break;
        case 'deleteRow':
          tableMdl.deleteRow();
          break;
        case 'deleteColumn':
          tableMdl.deleteColumn();
          break;
        case 'deleteTable':
          tableMdl.deleteTable();
          break;
        default:
          break;
      }
    }

  return (
    <div>
      <MainContainer>
        <HeaderContainer>
          <InputRow>
            <Input
              placeholder='제목을 입력하세요.'
              type='text'
              value={글제목}
              onChange={OnTextTitleChanged}>
            </Input>
          </InputRow>
          <InputRow>
            <Input
              placeholder='설교제목'
              type='text'
              width="50%"
              value={설교제목}
              onChange={OnSermonTitleChanged}>
            </Input>
            <Input
              placeholder='성경구절'
              type='text'
              width="30%"
              value={성경구절}
              onChange={OnBibleVerseChanged}>
            </Input>
            <Input
              type="date"
              width="20%"
              value={일자}
              onChange={handleDateChange}
              placeholder="일자선택">
            </Input>
          </InputRow>
        </HeaderContainer>
        <Article>
          <ReactQuill
            ref={quillRef}
            modules={modules}
            placeholder={'글을 입력하세요.'}
            onChange={EditChange}
            defaultValue={RequestFrom.content}
          />
        </Article>
      </MainContainer>
      <SubmitButton type='button' onClick={() => Validate()}>
        작 성 완 료
      </SubmitButton>
    </div>
  );
}

export default HtmlEditor;
