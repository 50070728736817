import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { KeyboardArrowRight } from '@mui/icons-material';
import { Typography } from '@mui/material';

const CinemaIntroWrapper = styled.div`
  width: 170px;
  background-color: #F2F2F5; /* 배경색 변경 */
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* 전체 내용을 가운데 정렬 */

  .top-text-wrapper {
    width: 100%;
    border-bottom: 1px solid #E8E8F0; /* 표시줄 추가 */
    margin-bottom: 10px; /* 다음 요소와의 간격 추가 */
    text-align: left; /* 텍스트 왼쪽 정렬 */
  }

  .links-wrapper {
    display: flex;
    flex-direction: column; /* 기본적으로 세로 정렬 */
    width: 100%;
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 이미지와 아이콘 간격 확보 */
    width: 150px; /* 컨테이너 가로 크기 설정 */
    height: 50px; /* 컨테이너 세로 크기 설정 */
    margin-bottom: 10px; /* 컨테이너 간의 간격 추가 */
    overflow: hidden; /* 넘치는 부분 숨기기 */
  }

  .link-content {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 이미지와 아이콘 간격 확보 */
    width: 100%; /* 전체 너비 사용 */
  }

  .link-image {
    display: block;
    max-width: 60%; /* 이미지가 컨테이너를 넘지 않도록 설정 */
    max-height: 100%; /* 이미지가 컨테이너를 넘지 않도록 설정 */
    object-fit: contain; /* 이미지가 영역에 맞게 조정 */
  }

  .arrow-icon {
    color: #708090;
  }

  @media (max-width: 768px) {
    width: 50%; /* 모바일 화면에서는 절반 너비 사용 */
    flex-direction: column; /* 모바일에서는 제목과 링크들을 세로로 배치 */
    height: 100%; /* 모바일에서 높이를 100% 사용 */
    
    .links-wrapper {
      flex-direction: column; /* 링크들을 세로로 배치 */
      justify-content: center; /* 중앙 정렬 */
      align-items: center; /* 링크들을 중앙에 배치 */
      height: 100%; /* 링크 영역의 높이 설정 */
    }
    
    .link-container {
      width: 100%; /* 모바일에서 각 링크 컨테이너가 100% 너비 사용 */
      height: auto; /* 높이 자동 설정 */
    }

    .link-content {
      justify-content: center; /* 중앙 정렬 */
    }
    
    .link-image {
      max-width: 120px; /* 모바일에서 이미지 너비를 제한 */
      height: auto; /* 모바일에서 이미지 높이 자동 설정 */
      object-fit: contain; /* 이미지가 영역에 맞게 조정 */
      padding: 10px;
    }

    .arrow-icon {
      display: none; /* 모바일에서 아이콘 숨기기 */
    }
  }
`;

export default function CinemaIntro() {
  return (
    <CinemaIntroWrapper>
      <div className="top-text-wrapper">
        <Typography variant="h6" style={{ color: '#1B1F23', fontWeight: 'bold' }}>
          극장소개
        </Typography>
        <Typography variant="subtitle2" style={{ color: '#708090', fontSize: '10px' }}>
          전국 300여개 개봉관
        </Typography>
      </div>
      <div className="links-wrapper">
        <div className="link-container">
          <Link to={'/cinema/info/theater'} className="link-content">
            <img className="link-image" src='/images/cgv.png' alt='CGV' />
            <KeyboardArrowRight className="arrow-icon" />
          </Link>
        </div>
        <div className="link-container">
          <Link to={'/cinema/info/lotte'} className="link-content">
            <img className="link-image" src='/images/lotte.png' alt='Lotte' />
            <KeyboardArrowRight className="arrow-icon" />
          </Link>
        </div>
        <div className="link-container">
          <Link to={'/cinema/info/mega'} className="link-content">
            <img className="link-image" src='/images/megabox.png' alt='Mega' />
            <KeyboardArrowRight className="arrow-icon" />
          </Link>
        </div>
      </div>
    </CinemaIntroWrapper>
  );
}