import React, { useState, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./screen/Home";
import Navigator from "./components/Navigator";
import { AuthProvider } from "./AuthContext";
import { HomeProvider } from "./screen/Home/HomeContext";
import CSCenter from "./screen/CSCenter";
import CSCenterNotice from "./screen/CSCenter/CSCenterNotice";
import CSCenterGuide from "./screen/CSCenter/CSCenterGuide";
import CSCenterFAQ from "./screen/CSCenter/CSCenterFAQ";
import CSCenterPurchase from "./screen/CSCenter/CSCenterPurchase";
import CSCenter1to1 from "./screen/CSCenter/CSCenter1to1";
import PostViewer from "./screen/PostViewer";
import WriteEditor from "./screen/WriteEditor";
import WriteEditorLite from "./screen/WriteEditorLite";
import UpdateEditer from "./screen/WriteEditor/Update";
import UpdateEditorLite from "./screen/WriteEditorLite/Update";
import CGV from "./screen/TheaterInfo/CGV";
import Lotte from "./screen/TheaterInfo/Lotte";
import Mega from "./screen/TheaterInfo/Mega";
import TheaterInfo from "./screen/TheaterInfo";
import MyPage from "./screen/MyPage";
import MyPageOrder from "./screen/MyPage/MyPageOrder";
import MyPageUser from "./screen/MyPage/MyPageUser";
import MyPageCoupon from "./screen/MyPage/MyPageCoupon";
import MyPageUserbank from "./screen/MyPage/MyPageUserbank";
import Footer, { Useinfo, Privacy, Kid } from "./components/Footer";
import StickerContainer from "./screen/Sticker";
import { useAuth } from "./AuthContext";

import Login from "./components/Login";
import AutoLogin from "./screen/AutoLogin";
import PasswordResetForm from "./components/PasswordReset";
import RegisterForm from "./components/Register";
import CouponRegister from "./screen/Sticker/RegisterCoupon";
import MovieInfo from "./screen/MovieInfo";
import IngMovieInfo from "./screen/MovieInfo/IngMovieInfo";
import PrevMovieInfo from "./screen/MovieInfo/PrevMovieInfo";
import DetailsMovieInfo from "./screen/MovieInfo/DetailsMovieInfo";
import MovieTicketing from "./screen/MovieTicketing";
import ProductRecommendation, {
  ShippingInfo,
  OrderComplete,
  ProductDetailPage,
} from "./screen/Product";
import { PaymentSuccess } from "./screen/PgPayment/PaymentSuccess";
import { PaymentFail } from "./screen/PgPayment/PaymentFail";
import PgPayment from "./screen/PgPayment";
import TicketPurchase from "./screen/TicketPurchase";
import MaintenancePopup from "./components/MaintenancePopup";
import AppSetupConfig from "./AppSetupConfig";
import { getSessionFunc } from "./api/func/userFunc";
import { PrivateRoute, PublicRoute } from "./components/Routes/PrivateRoute";

function App() {
  let navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, user, login, logout, setIsLoggedIn, setUser } = useAuth();
  const [isMaintenance, setIsMaintenance] = useState(false);

  const [showPopup, setShowPopup] = useState(false); // 팝업 표시 상태

  useEffect(() => {
    const inputBuffer = [];
    const handleKeyDown = (event) => {
      if (event.key) {
        if (event.key.length === 1) {
          inputBuffer.push(event.key.toLowerCase());
          if (inputBuffer.join("").includes("setup")) {
            setShowPopup(true); // "setup" 입력 시 팝업 표시
            inputBuffer.length = 0; // 버퍼 초기화
          }
        } else if (event.key === "Enter") {
          inputBuffer.length = 0; // Enter 입력 시 버퍼 초기화
        } else if (event.key === "Backspace") {
          inputBuffer.pop(); // 버퍼에서 마지막 글자 제거
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const closePopup = () => setShowPopup(false); // 팝업 닫기 핸들러

  return (
    <AuthProvider>
      <div className="App">
        <Navigator />
        <div className="content-wrapper">
          <main>
            <Routes>
              <Route
                path="/"
                element={
                  <HomeProvider>
                    <Home />
                  </HomeProvider>
                }
              />
              <Route
                path="/autoLogin"
                element={<AutoLogin></AutoLogin>}
              ></Route>
              <Route
                path="/pay_success"
                element={<PaymentSuccess></PaymentSuccess>}
              />
              <Route path="/pay_fail" element={<PaymentFail></PaymentFail>} />
              <Route path="/pg_payment" element={<PgPayment />} />
              <Route path="/ticket_purchase" element={<TicketPurchase />} />
              <Route path="/user/user/login" element={<Login />} />
              <Route
                path="/user/user/finduserinfo"
                element={<PasswordResetForm />}
              />
              <Route path="/user/user/create" element={<RegisterForm />} />
              <Route path="/etc/useinfo" element={<Useinfo />} />
              <Route path="/etc/privacy" element={<Privacy />} />
              <Route path="/etc/kid" element={<Kid />} />

              <Route
                path="/movie/ticketing"
                element={
                  <HomeProvider>
                    <MovieTicketing />
                  </HomeProvider>
                }
                key="MovieTicketing"
              />
              <Route
                path="/movie/info/*"
                element={
                  <HomeProvider>
                    <MovieInfo />
                  </HomeProvider>
                }
                key="MovieInfo"
              >
                {/* <Route path="/movie/info/index" element={<IngMovieInfo />} key="IngMovieInfo" />
                <Route path="/movie/info/index" element={<PrevMovieInfo />} key="PrevMovieInfo" /> */}
                <Route
                  path="details/:movie_code"
                  element={<DetailsMovieInfo />}
                  key="DetailsMovieInfo"
                />
              </Route>
              <Route
                path="/cinema/info"
                element={
                  <HomeProvider>
                    <TheaterInfo />
                  </HomeProvider>
                }
                key="TheaterInfo"
              >
                <Route
                  path="/cinema/info/theater"
                  element={
                    <HomeProvider>
                      <CGV />
                    </HomeProvider>
                  }
                  key="CGV"
                />
                <Route
                  path="/cinema/info/lotte"
                  element={<Lotte />}
                  key="Lotte"
                />
                <Route path="/cinema/info/mega" element={<Mega />} key="Mega" />
              </Route>
              <Route
                path="/mypage"
                element={
                  <HomeProvider>
                    <MyPage />
                  </HomeProvider>
                }
                key="MyPage"
              >
                <Route
                  path="/mypage/order/3"
                  element={
                    <HomeProvider>
                      <MyPageOrder />
                    </HomeProvider>
                  }
                  key="MyPageOrder"
                />
                <Route
                  path="/mypage/user/edit"
                  element={<MyPageUser />}
                  key="MyPageUser"
                />
                <Route
                  path="/mypage/coupon"
                  element={<MyPageCoupon />}
                  key="MyPageCoupon"
                />
                <Route
                  path="/mypage/userbank/create"
                  element={<MyPageUserbank />}
                  key="MyPageUserbank"
                />
              </Route>
              <Route
                path="/cscenter/board"
                element={
                  <HomeProvider>
                    <CSCenter />
                  </HomeProvider>
                }
                key="CSCenter"
              >
                <Route path="/cscenter/board/1" element={<CSCenterNotice />} />
                <Route
                  path="/cscenter/board/guide"
                  element={
                    <HomeProvider>
                      <CSCenterGuide />
                    </HomeProvider>
                  }
                />
                <Route path="/cscenter/board/2" element={<CSCenterFAQ />} />
                <Route
                  path="/cscenter/board/3"
                  element={<CSCenterPurchase />}
                />
                <Route path="/cscenter/board/17" element={<CSCenter1to1 />} />
                <Route
                  path="/cscenter/board/QaBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/NoticeBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/PurchaseBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/OneToOneBoard/:id"
                  element={<PostViewer />}
                  key="PostViewer"
                />
                <Route
                  path="/cscenter/board/create"
                  element={<WriteEditorLite />}
                  key="WriteEditorLite"
                />
                <Route
                  path="/cscenter/board/update/:id"
                  element={<UpdateEditorLite />}
                  key="UpdateEditorLite"
                />
              </Route>
            </Routes>
          </main>
          {/* main 영역 우측에 StickerContainer 위치 */}
          <div className="sticker-right">
            <StickerContainer />
          </div>
        </div>
        <Footer />
        {isMaintenance && <MaintenancePopup />}
        {showPopup && (
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <AppSetupConfig />
          </div>
        )}
      </div>
    </AuthProvider>
  );
}

function Popup() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/mypage/coupon/registercoupon"
          element={<CouponRegister />}
        />
        <Route
          path="/mypage/coupon/productRecommendation"
          element={<ProductRecommendation />}
          key="ProductRecommendation"
        />
        <Route path="/mypage/coupon/shipping" element={<ShippingInfo />} />
        <Route
          path="/mypage/coupon/detailPage/:id"
          element={<ProductDetailPage />}
        />
        <Route
          path="/mypage/coupon/order-complete"
          element={<OrderComplete />}
        />
      </Routes>
    </AuthProvider>
  );
}

export { App, Popup };
