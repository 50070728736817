import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../../AuthContext';
import { useNavigate } from 'react-router-dom';

const couponTypes = {
  "type_g": "영화예매권A",
  "type_i": "제주도쿠폰",
  "type_l": "씨네1인권",
  "type_m": "영화VIP",
  "type_n": "2인중1인권",
  "type_h": "영화예매권B",
  "type_j": "영화예매권",
  "type_d": "영화예매할인권(5000P)",
  "type_k": "동반할인권"
};

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin: 20px 0;
  text-align: left;
  border-bottom: 1px solid #DDDDDD;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #CC9933;
  margin-bottom: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4f4;
  text-align: center;
  font-size: 0.8rem;
  color: #555;
`;

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.7rem;
  color: #555;
  text-decoration: ${({isExpired, isCouponStatusFalse }) => (isExpired || isCouponStatusFalse) ? 'line-through' : 'none'};
  color: ${({ isExpired, isCouponStatusFalse }) => (isExpired || isCouponStatusFalse )? '#aaa' : 'inherit'};
  position: relative;

  &:hover .delete-btn {
    display: inline-block;
  }
`;

const DeleteButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: red;
  font-size: 1rem;
  cursor: pointor;

  &:hover {
    color: darkred;
  }
`;


export default function MyPageCoupon() {
  const [coupons, setCoupons] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/user/user/login');
    } else {
      fetchCoupons();
    }
  }, [user, navigate]);

  const fetchCoupons = () => {
    fetch('/request/getUserCoupons')
      .then((res) => res.json())
      .then((data) => {
        setCoupons(data);
      })
      .catch((error) => {
        console.error('Error fetching coupons:', error);
      });
  };

  const deleteCoupon = (coupon_code) => {
    fetch('/request/deleteCouponCert', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coupon_code }),
    })
      .then((res) => {
        if (res.ok) {
          setCoupons((prevCoupons) => prevCoupons.filter((coupon) => coupon.coupon_code !== coupon_code));
        } else {
          console.error('Failed to delete coupon');
        }
      })
      .catch((error) => {
        console.error('Error deleting coupon:', error);
      });
  };

  return (
    <Container>
      <Title>쿠폰관리</Title>
      <Divider />
      <Table>
        <thead>
          <tr>
            <Th>쿠폰번호</Th>
            <Th>쿠폰이름</Th>
            <Th>유효기간</Th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => {
            const isExpired = new Date(coupon.reg_exp_period_e) < new Date();
            const isCouponStatusFalse = coupon.coupon_status === "사용완료";
            return (
              <tr key={coupon.coupon_code}>
                <Td isExpired={isExpired} isCouponStatusFalse={isCouponStatusFalse}>{coupon.coupon_code}</Td>
                <Td isExpired={isExpired} isCouponStatusFalse={isCouponStatusFalse}>
                  {couponTypes[coupon.coupon_type] || coupon.coupon_type}
                  {(isExpired || isCouponStatusFalse) && (
                    <DeleteButton
                      className="delete-btn"
                      onClick={() => deleteCoupon(coupon.coupon_code)}
                    >
                      X
                    </DeleteButton>
                  )}
                </Td>
                <Td isExpired={isExpired} isCouponStatusFalse={isCouponStatusFalse}>
                  {new Date(coupon.reg_exp_period_e).toLocaleDateString()}
                  {isExpired && ' (기간만료)'}
                  {isCouponStatusFalse && ' (사용완료)'}
                  <input hidden value={coupon.max_order_cnt}></input>
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
