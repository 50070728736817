import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useAuth } from "../../AuthContext";
import { Button } from "@mui/material";
import Cookies from "js-cookie";
import { setSessionFunc } from "../../api/func/userFunc";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const FormBox = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 800px;
  margin-top: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  border-bottom: 1px solid #dddddd;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column; /* 수직으로 변경 */
  }
`;

const LoginSection = styled.div`
  width: 50%;
  padding: 50px;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 0.8rem;
  box-sizing: border-box; /* 패딩과 보더를 포함하여 너비 계산 */

  @media (max-width: 768px) {
    width: 100%; /* 화면 크기가 768px 이하일 때 전체 너비 사용 */
    padding: 20px;
  }
`;

const LoginBanner = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    display: none; /* 화면 크기가 768px 이하일 때 숨김 */
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column; /* 수직으로 정렬 */
    align-items: flex-start; /* 왼쪽 정렬 */
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: 0.8rem;
  color: #555;
  margin-bottom: 5px;
  margin-left: 5px;

  @media (max-width: 768px) {
    margin-left: 0; /* 왼쪽 여백 제거 */
    margin-top: 0.5rem; /* 상단 여백 추가 */
  }
`;

const InputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    flex-direction: column; /* 600px 이하에서 수직으로 변경 */
  }
`;

const InputFieldGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;

  label {
    width: 70px;
    margin-right: 10px;
    font-size: 0.8rem;
    color: #333;
  }

  input {
    width: calc(100% - 80px);
    height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    color: #333;
  }
`;

const LoginButton = styled.button`
  height: 100px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: 10px;
  padding: 10px 20px; /* 패딩 추가 */

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    width: 100%; /* 600px 이하에서 전체 너비 사용 */
    height: 40px;
    margin-top: 10px; /* 위에 여백 추가 */
  }
`;

const RegisterButton = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  background-color: #ffc107;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 8px 16px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #5a6268;
  }
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;

  label {
    display: flex;
    align-items: center;
    color: #666;
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  input {
    margin-right: 5px;
  }
`;

const Message = styled.p`
  color: red;
  text-align: center;
`;

const PasswordResetLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const CouponMessage = styled.p`
  font-size: 0.8rem;
  color: #555;
  margin-top: 10px;
`;

function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login, isLoggedIn } = useAuth();
  useEffect(() => {
    const handleEnterKey = (e) => {
      if (e.key === "Enter") {
        handleLoginClick();
      }
    };
    window.addEventListener("keydown", handleEnterKey);
    return () => {
      window.removeEventListener("keydown", handleEnterKey);
    };
  }, [phoneNumber, password, rememberMe]); 
  
  useEffect(() => {
    const savedPhoneNumber = Cookies.get("savedPhoneNumber");
    if (savedPhoneNumber) {
      setPhoneNumber(savedPhoneNumber);
      setRememberMe(true);
    }
  }, []);

  const validateForm = () => {
    if (!phoneNumber) {
      setMessage("전화번호를 입력해주세요.");
      return false;
    }
    if (!password) {
      setMessage("비밀번호를 입력해주세요.");
      return false;
    }
    setMessage("");
    return true;
  };

  const handleLoginClick = async () => {
    if (validateForm()) {
      try {
        const response = await fetch(`/request/login`, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            phone_number: phoneNumber,
            password: password,
          }),
        });

        if (response.ok) {
          const userData = await response.json();
          console.log(JSON.stringify(userData));
          alert(`${userData.name}님, 로그인에 성공하였습니다!`);
          login(userData);
          const redirectTo = location.state?.from || "/";

          if (rememberMe) {
            Cookies.set("savedPhoneNumber", userData.phone_number, {
              expires: 7,
            });
          } else {
            Cookies.remove("savedPhoneNumber");
          }

          // 세션 서버 저장
          const setSession = await setSessionFunc(userData);
          // console.log(setSession);
          if (setSession.code == 200) {
            // 서버 세션에 데이터 저장 완료.
            console.log("서버 세션에 데이터 저장 완료.");
            // console.log(redirectTo);
            navigate(redirectTo);
          } else {
            // 서버 세션에 데이터 저장 실패.
            setMessage("서버 확인이 필요합니다.");
          }
        } else {
          if (response.status === 401) {
            setMessage("전화번호 또는 비밀번호가 잘못되었습니다.");
          } else {
            setMessage("로그인에 실패하였습니다. 다시 시도해주세요.");
          }
        }
      } catch (error) {
        console.error("Error:", error);
        setMessage("로그인 요청 중 문제가 발생했습니다.");
      }
      setPhoneNumber("");
      setPassword("");
    }
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <Container>
      <FormBox>
        <Header>
          <TitleWrapper>
            <Title>로그인</Title>
            <SubTitle>
              로그인을 하셔서 더 다양한 서비스를 자유롭게 이용해보세요.
            </SubTitle>
          </TitleWrapper>
        </Header>
        <Content>
          <LoginSection>
            <Message>{message}</Message>
            <InputFieldWrapper>
              <InputFieldGroup>
                <InputField>
                  <label>휴대폰</label>
                  <input
                    type="text"
                    name="phone_number"
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </InputField>
                <InputField>
                  <label>비밀번호</label>
                  <input
                    type="password"
                    name="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputField>
              </InputFieldGroup>
              <LoginButton type="button" onClick={handleLoginClick}>
                로그인
              </LoginButton>
            </InputFieldWrapper>
            <Option>
              <label>
                <input
                  type="checkbox"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                휴대폰번호 저장
              </label>
              <label>
                <input type="checkbox" /> 자동 로그인
              </label>
            </Option>
            <PasswordResetLink to={"/user/user/finduserinfo"}>
              비밀번호 찾기
            </PasswordResetLink>
            <CouponMessage>
              기존 인증고객은 로그인 후 쿠폰 추가 등록하세요
            </CouponMessage>
            <RegisterButton to={"/user/user/create"}>회원가입</RegisterButton>
          </LoginSection>
          <LoginBanner>
            <img src="/images/banner_login.gif" alt="로그인 배너" />
          </LoginBanner>
        </Content>
      </FormBox>
    </Container>
  );
}

function Logout() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch("/request/logout", {
        method: "GET",
      });

      if (response.ok) {
        logout();
        sessionStorage.removeItem("user");
        navigate("/");
      } else {
        console.error("Failed to logout");
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleLogout}
      sx={{ backgroundColor: "#FFC107", color: "#1B1F23", fontSize: "12px" }}
    >
      로그아웃
    </Button>
  );
}

export default Login;
export { Logout };
