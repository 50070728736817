import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const CGVWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: gray;
  width: 100%;
  height: 100%;
`;

const CGVImage = styled.img`
  margin-top: 50px;
  max-width: 100%;
  height: auto;
`;

export default function CGV() {
  return (
    <CGVWrapper>
      <CGVImage src="/images/cgv_img.gif" alt="CGV" />
      <Outlet />
    </CGVWrapper>
  );
}
