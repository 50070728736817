import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 전체 화면 너비 사용 */
`;

const FormBox = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  width: 800px;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Header = styled.div`
  position: relative;
  text-align: left;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 0;
  display: inline;

  @media (max-width: 768px) {
    display: block; /* block으로 변경하여 줄바꿈 발생 */
  }
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin: 0;
  display: inline;
  margin-left: 15px;

  @media (max-width: 768px) {
    display: block; /* block으로 변경하여 줄바꿈 발생 */
    margin-left: 0; /* 왼쪽 여백 제거 */
    margin-top: 10px; /* 상단 여백 추가 */
  }
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #CC9933;
  margin: 20px 0; /* 상하 여백 추가 */
`;

const HeaderDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
`;

const LightDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  margin: 10px 0; /* 상하 여백 추가 */
`;

const Content = styled.div`
  padding: 20px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center; /* 수직 중앙 정렬 */
    text-align: center; /* 텍스트 중앙 정렬 */
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative; /* 위치 조정을 위한 설정 */

  label {
    width: 80px;
    margin-right: 10px;
    font-size: 0.8rem;
    color: #333;
  }

  input {
    max-width: 250px;
    flex: 1;
    height: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.8rem;
    color: #333;
  }

  small {
    margin-left: 10px;
    font-size: 0.8rem;

    @media (max-width: 600px) {
      position: absolute; /* 위치 절대값 설정 */
      top: 100%; /* input 밑으로 이동 */
      left: 0; /* 왼쪽 정렬 */
      margin-left: 10px; /* 여백 설정 */
      margin-top: 30px; /* 상단 여백 추가 */
    }
  }
`;

const PasswordResetButton = styled.button`
  height: 40px;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PasswordResetLink = styled(Link)`
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
  display: block;
  font-size: 0.8rem;

  &:hover {
    text-decoration: underline;
  }
`;

const Message = styled.p`
  color: red;
  text-align: center;
`;

function PasswordResetForm() {
  const [resetName, setResetName] = useState("");
  const [resetPhone, setResetPhone] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    try {
      const response = await fetch('/request/password-reset', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: resetName, phone: resetPhone })
      });

      if (response.ok) {
        setMessage("비밀번호 재설정 링크가 휴대폰 번호로 전송되었습니다.");
      } else {
        setMessage("비밀번호 재설정에 실패했습니다. 이름과 휴대폰 번호를 확인해주세요.");
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage("비밀번호 재설정 요청 중 문제가 발생했습니다.");
    }
  };

  return (
    <Container>
      <FormBox>
        <Header>
          <Title>비밀번호 재설정</Title>
          <SubTitle>가입시 등록한 휴대폰번호로 새 비밀번호를 발송해 드립니다.</SubTitle>
          <HeaderDivider />
        </Header>
        <Divider />
        <Content>
          <Message>{message}</Message>
          <InputField>
            <label>이름</label>
            <input
              type="text"
              required
              value={resetName}
              onChange={(e) => setResetName(e.target.value)}
            />
          </InputField>
          <LightDivider />
          <InputField>
            <label>휴대폰번호</label>
            <input
              type="text"
              required
              value={resetPhone}
              onChange={(e) => setResetPhone(e.target.value)}
            />
            <small>("-"를 빼고 입력해주세요. 예:01099551111)</small>
          </InputField>
          <LightDivider />
          <PasswordResetButton type="button" onClick={handlePasswordReset}>
            확인
          </PasswordResetButton>
          <PasswordResetLink to={'/user/user/login'}>
            로그인 화면으로 돌아가기
          </PasswordResetLink>
        </Content>
      </FormBox>
    </Container>
  );
}

export default PasswordResetForm;
