import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
`;



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableHeader = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
`;

const TableCell = styled.td`
  padding: 12px;
`;

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

// Styled components for the modal
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 600px;
  height: 650px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  background-color: transparent; /* 투명 배경색 설정 */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: white; /* 흰색으로 버튼 글자 색 설정 */
`;


function PgPayment() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    STOREID: "2400000058",
    CRYPTO_KEY: "4032EA1609A6C35FFAF15944571F625C",
    STORE_NAME: "주식회사 언체인",
    STORE_LOGO: "https://demo.paynuri.com/images/logo_shop.png",
    BUSINESS_NO: "5238103358",
    TX_USER_DEFINE: "언체인^457001-01-497662",
    TRAN_NO: "12345678910",
    PRODUCTTYPE: "2",
    TAX_FREE_CD: "00",
    TAX_AMT: "93",
    GOODS_NAME: "영화권",
    AMT: "1004",
    QUANTITY: "1",
    SALE_DATE: "",
    CUSTOMER_NAME: "홍길동",
    NOTICE_URL: "",
    RETURN_URL: "https://cinema.skynet.re.kr/request/return_pay",
    COMPLETE_URL: "https://cinema.skynet.re.kr/pay_success",
    CANCEL_URL: "https://cinema.skynet.re.kr/pay_fail",
    STORE_URL: "cinema.skynet.re.kr",
    CUSTOMER_EMAIL: "abc@email.com",
    CUSTOMER_TEL: "0101112222",
    CUSTOMER_IP: "110.15.204.218",
    ETC_DATA1: "테스트 데이터 여분1",
    ETC_DATA2: "테스트 데이터 여분2",
    ETC_DATA3: "테스트 데이터 여분3",
    TRAN_TYPE: "CARD"
  });

  const formRef = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    const today = new Date().toISOString().slice(2, 10).replace(/-/g, '');
    const tranNo = new Date().toISOString().replace(/[-:.TZ]/g, '');

    setFormData((prevData) => ({
      ...prevData,
      SALE_DATE: today,
      TRAN_NO: tranNo,
    }));
  }, []);

  const handlePayment = () => {
    const alertMessage = Object.entries(formData)
      .map(([key, value]) => `${key}: ${value}`)
      .join('\n');
    console.log(alertMessage);

    if (window.confirm(`다음 정보를 확인해주세요:\n\n${alertMessage}`)) {
      // 다이얼로그 열기
      setDialogOpen(true);
    }
  };

  const handleCloseModal = () => {
    setDialogOpen(false);
  };

  // 결제 완료 후 부모 창에서 처리
  const handleIframeLoad = () => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeLocation = iframe.contentWindow.location.href;

      // 성공 URL과 실패 URL을 비교하여 처리
      if (iframeLocation.includes('pay_success')) {
        alert('결제 성공!');
        window.location.href = formData.COMPLETE_URL;
        setDialogOpen(false); // 모달 닫기
      } else if (iframeLocation.includes('pay_fail')) {
        alert('결제 실패!');
        window.location.href = formData.CANCEL_URL;
        setDialogOpen(false); // 모달 닫기
      }
    }
  };

  useEffect(() => {
    if (dialogOpen && formRef.current) {
      formRef.current.submit();
    }
  }, [dialogOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
   <FormContainer>
  <Table>
    <tbody>
      <TableRow>
        <TableCell style={{ textAlign: 'center', fontSize: 'xx-large', fontWeight: 'bolder' }}>
          <Title>전자결제 모듈</Title>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Table widTableHeader="100%" border="1" cellSpacing="0" cellPadding="2" align="center">
            <TableRow>
              <TableHeader>가맹점번호</TableHeader>
              <TableCell>
                <Input type="text" id="STOREID" name="STOREID" value={formData.STOREID} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>가맹점KEY</TableHeader>
              <TableCell>
                <Input type="text" id="CRYPTO_KEY" name="CRYPTO_KEY" value={formData.CRYPTO_KEY} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>가맹점명</TableHeader>
              <TableCell>
                <Input type="text" id="STORE_NAME" name="STORE_NAME" value={formData.STORE_NAME} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>가맹점로고</TableHeader>
              <TableCell>
                <Input type="text" id="STORE_LOGO" name="STORE_LOGO" value={formData.STORE_LOGO} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>사업자번호</TableHeader>
              <TableCell>
                <Input type="text" id="BUSINESS_NO" name="BUSINESS_NO" value={formData.BUSINESS_NO} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>계좌이체통장정보</TableHeader>
              <TableCell>
                <Input type="text" id="TX_USER_DEFINE" name="TX_USER_DEFINE" value={formData.TX_USER_DEFINE} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>가맹점주문번호</TableHeader>
              <TableCell>
                <Input type="text" id="TRAN_NO" name="TRAN_NO" value={formData.TRAN_NO} onChange={handleChange} size="20" maxLengTableHeader="20" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>상품구분</TableHeader>
              <TableCell>
                <Input type="radio" id="PRODUCTTYPE_1" name="PRODUCTTYPE" value="1" checked={formData.PRODUCTTYPE === '1'} onChange={handleChange} />
                <label htmlFor="PRODUCTTYPE_1">디지털</label>
                <Input type="radio" id="PRODUCTTYPE_2" name="PRODUCTTYPE" value="2" checked={formData.PRODUCTTYPE === '2'} onChange={handleChange} />
                <label htmlFor="PRODUCTTYPE_2">실물</label>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>과세/비과세</TableHeader>
              <TableCell>
                <Input type="radio" id="TAX_FREE_CD_1" name="TAX_FREE_CD" value="00" checked={formData.TAX_FREE_CD === '00'} onChange={handleChange} />
                <label htmlFor="TAX_FREE_CD_1">과세</label>
                <Input type="radio" id="TAX_FREE_CD_2" name="TAX_FREE_CD" value="01" checked={formData.TAX_FREE_CD === '01'} onChange={handleChange} />
                <label htmlFor="TAX_FREE_CD_2">비과세</label>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>세금</TableHeader>
              <TableCell>
                <Input type="text" id="TAX_AMT" name="TAX_AMT" value={formData.TAX_AMT} onChange={handleChange} size="8" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>상품명</TableHeader>
              <TableCell>
                <Input type="text" id="GOODS_NAME" name="GOODS_NAME" value={formData.GOODS_NAME} onChange={handleChange} size="15" maxLength="20" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>결제금액</TableHeader>
              <TableCell>
                <Input type="text" id="AMT" name="AMT" value={formData.AMT} onChange={handleChange} size="8" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>상품갯수</TableHeader>
              <TableCell>
                <Input type="text" id="QUANTITY" name="QUANTITY" value={formData.QUANTITY} onChange={handleChange} size="3" maxLength="3" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>거래일자</TableHeader>
              <TableCell>
                <Input type="text" id="SALE_DATE" name="SALE_DATE" value={formData.SALE_DATE} onChange={handleChange} size="8" maxLength="6" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>고객명</TableHeader>
              <TableCell>
                <Input type="text" id="CUSTOMER_NAME" name="CUSTOMER_NAME" value={formData.CUSTOMER_NAME} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>입금통보URL(가상계좌)</TableHeader>
              <TableCell>
                <Input type="text" id="NOTICE_URL" name="NOTICE_URL" value={formData.NOTICE_URL} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>리턴URL</TableHeader>
              <TableCell>
                <Input type="text" id="RETURN_URL" name="RETURN_URL" value={formData.RETURN_URL} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>결제성공URL</TableHeader>
              <TableCell>
                <Input type="text" id="COMPLETE_URL" name="COMPLETE_URL" value={formData.COMPLETE_URL} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>결제취소URL</TableHeader>
              <TableCell>
                <Input type="text" id="CANCEL_URL" name="CANCEL_URL" value={formData.CANCEL_URL} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>가맹점url</TableHeader>
              <TableCell>
                <inInputput type="text" id="STORE_URL" name="STORE_URL" value={formData.STORE_URL} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>고객이메일</TableHeader>
              <TableCell>
                <Input type="text" id="CUSTOMER_EMAIL" name="CUSTOMER_EMAIL" value={formData.CUSTOMER_EMAIL} onChange={handleChange} size="30" maxLength="100" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>고객전화번호</TableHeader>
              <TableCell>
                <Input type="text" id="CUSTOMER_TEL" name="CUSTOMER_TEL" value={formData.CUSTOMER_TEL} onChange={handleChange} size="13" maxLength="20" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>고객 IP</TableHeader>
              <TableCell>
                <Input type="text" id="CUSTOMER_IP" name="CUSTOMER_IP" value={formData.CUSTOMER_IP} onChange={handleChange} size="13" maxLength="20" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>여분데이터1</TableHeader>
              <TableCell>
                <Input type="text" id="ETC_DATA1" name="ETC_DATA1" value={formData.ETC_DATA1} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>여분데이터2</TableHeader>
              <TableCell>
                <Input type="text" id="ETC_DATA2" name="ETC_DATA2" value={formData.ETC_DATA2} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>여분데이터3</TableHeader>
              <TableCell>
                <Input type="text" id="ETC_DATA3" name="ETC_DATA3" value={formData.ETC_DATA3} onChange={handleChange} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableHeader>거래종류</TableHeader>
              <TableCell>
                <Input type="radio" id="TRADE_TYPE_CARD" name="TRAN_TYPE" value="CARD" checked={formData.TRAN_TYPE === 'CARD'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_CARD">카드</label>
                {/* <Input type="radio" id="TRADE_TYPE_CASH" name="TRAN_TYPE" value="CASH" checked={formData.TRAN_TYPE === 'CASH'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_CASH">현금영수증</label>
                <Input type="radio" id="TRADE_TYPE_VRTL" name="TRAN_TYPE" value="VRTL" checked={formData.TRAN_TYPE === 'VRTL'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_VRTL">가상계좌</label>
                <Input type="radio" id="TRADE_TYPE_BANK" name="TRAN_TYPE" value="BANK" checked={formData.TRAN_TYPE === 'BANK'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_BANK">계좌이체</label>
                <Input type="radio" id="TRADE_TYPE_PHON" name="TRAN_TYPE" value="PHON" checked={formData.TRAN_TYPE === 'PHON'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_PHON">휴대폰</label>
                <Input type="radio" id="TRADE_TYPE_MNUL" name="TRAN_TYPE" value="MNUL" checked={formData.TRAN_TYPE === 'MNUL'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_MNUL">수기결제</label>
                <Input type="radio" id="TRADE_TYPE_KKOP" name="TRAN_TYPE" value="KKOP" checked={formData.TRAN_TYPE === 'KKOP'} onChange={handleChange} />
                <label htmlFor="TRADE_TYPE_KKOP">카카오페이</label> */}
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      </TableRow>
    </tbody>
  </Table>
    {/* 결제용 다이얼로그 */}
       {/* 결제용 모달 */}
       {dialogOpen && (
        <ModalOverlay>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>×</CloseButton>
            <iframe
              name="iframe_payment"
              id="iframe_payment"
              src="about:blank"
              width="670"
              height="800"
              scrolling="no"
              frameBorder="0"
              ref={iframeRef}
              onLoad={handleIframeLoad} // onLoad 이벤트 핸들러 추가
            ></iframe>
          </ModalContent>
        </ModalOverlay>
      )}


      {/* Hidden Form */}
      <form
        action="https://pg.paynuri.com/paymentgateway/pc/reqPay.do"
        method="post"
        name="frmPayment"
        id="frmPayment"
        target="iframe_payment"
        acceptCharset="EUC-KR"
        ref={formRef}
        style={{ display: 'none' }}
      >
        {/* Hidden inputs to match form data */}
        {Object.entries(formData).map(([key, value]) => (
          <input key={key} type="hidden" name={key} value={value} />
        ))}
      </form>

      {/* 폼 외부에 있는 결제 버튼 */}
      <button onClick={handlePayment} style={{ width: '200px', height: '40px' }}>
        결제하기
      </button>
    </FormContainer>
  );
}

export default PgPayment;