import React, { useState, useEffect } from 'react';
import { Outlet, NavLink, useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../AuthContext';
import Pagination from '@mui/material/Pagination';
const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  text-align: center;
`;

const CloseButton = styled.button`
  margin-top: 20px;
  background-color: #0056b3;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #FFC107;
  }
`;

const Container = styled.div`
  width: 90%; /* 기본적으로 90% 너비 사용 */
  margin: 0 auto;
  padding: 0 1rem; /* 양쪽에 패딩 추가 */
  background-color: #fff;

  @media (max-width: 768px) {
    width: 100%; /* 화면의 너비가 768px 이하일 때 전체 너비 사용 */
    padding: 0 0.5rem; /* 작은 화면에서 패딩 조정 */
  }
`;

const Header = styled.div`
  margin-bottom: 1rem;
  text-align: left;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin-bottom: 0;
  border-bottom: 1px solid #dddddd;
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #555;
  margin-top: 1rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: white;
`;

const Tbody = styled.tbody`
  tr {
    border-bottom: 1px solid #ddd;
  }
`;

const Td = styled.td`
  padding: 1rem;
  text-align: left;

  @media (max-width: 768px) {
    padding: 0.5rem; /* 작은 화면에서 패딩 조정 */
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  @media (max-width: 425px) {
    flex-direction: column; /* 작은 화면에서는 수직 방향으로 정렬 */
    row-gap: 0.5rem; /* 행 간 간격 추가 */
  }
`;

const ButtonWrapper = styled.div`
  text-align: left;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: white;
`;

const StyledPagination = styled(Pagination)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
// 날짜 포맷 함수 추가
const formatLocalDate = (dateString) => {
  const date = new Date(dateString);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // 타임존 보정
  return localDate.toISOString().slice(0, 10); // YYYY-MM-DD 형식으로 반환
};
export default function MyPageOrder() {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // 팝업 상태
  const [selectedTicketNumber, setSelectedTicketNumber] = useState(null); // 선택된 발권번호
  const ordersPerPage = 3;
  const { user } = useAuth();
  const navigate = useNavigate();
  const handlePopupOpen = (ticketNumber) => {
    setSelectedTicketNumber(ticketNumber); // 발권번호 저장
    setIsPopupOpen(true); // 팝업 열기
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false); // 팝업 닫기
  };
  useEffect(() => {
    if (!user) {
      navigate('/user/user/login');
    } else {
      const fetchOrders = async () => {
        try {
          const response = await fetch('/order/orders');
          const data = await response.json();
          // 주문 데이터를 최신순으로 정렬
          setOrders(data.reverse());
        } catch (error) {
          console.error('주문 데이터를 가져오는 중 오류 발생:', error);
        }
      };

      fetchOrders();
    }
  }, [user, navigate]);

  const totalOrders = orders.length;
  const totalPages = Math.ceil(totalOrders / ordersPerPage);
  const displayedOrders = orders.slice((page - 1) * ordersPerPage, page * ordersPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const getDisplayValue = (str) => {
    switch (str) {
      case 'success':
        return '성공';
      case 'refund_pending':
        return '환불대기';
      case 'timeout_cancel':
        return '미입금취소';
      case 'pending':
        return '예매대기';
      case 'free':
        return '무료';
      case 'cancel_pending':
        return '무료';
      default:
        return str;
    }
  };
  return (
    <Container>
      <Header>
        <Title>예매/취소내역</Title>
        <SubTitle>{totalOrders} 건 조회되었습니다.</SubTitle>
      </Header>
      <Table>
        <Tbody>
          {displayedOrders.map((order, index) => (
            <tr key={index}>
              <Td>
                <Row>
                  <div>영화명: {order.movie_title}</div>
                </Row>
                <Row>
                  <div>관람일시: {formatLocalDate(order.date)}</div>
                  <div>관람극장: {order.theater} / 관람인원: {order.viewer_count}인</div>
                </Row>
                <Row>
                  <div>결제상태: {getDisplayValue(order.payment_status)} / 주문상태: {order.order_status}</div>
                  <div>주문일: {formatLocalDate(order.created_at)}</div>
                </Row>
                <ButtonWrapper>
                  <Button onClick={() => handlePopupOpen(order.complate_code)}>
                    발권번호 확인
                  </Button>
                </ButtonWrapper>
              </Td>
            </tr>
          ))}
        </Tbody>
      </Table>
      {isPopupOpen && (
        <PopupOverlay>
          <PopupContainer>
            <h2>발권번호</h2>
            <p>{selectedTicketNumber ? selectedTicketNumber : "발권대기중입니다."}</p>
            <CloseButton onClick={handlePopupClose}>닫기</CloseButton>
          </PopupContainer>
        </PopupOverlay>
      )}
      <StyledPagination
        count={totalPages}
        shape="rounded"
        page={page}
        onChange={handleChangePage}
      />
      <Outlet />
    </Container>
  );
}

export function OrderDetail() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(`/order/orders/${id}`);
        const data = await response.json();
        setOrder(data);
      } catch (error) {
        console.error('주문 데이터를 가져오는 중 오류 발생:', error);
      }
    };

    fetchOrder();
  }, [id]);

  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <Header>
        <Title>{order.movie_title}</Title>
        <SubTitle>{order.views} 조회</SubTitle>
      </Header>
      <div>관람일시: {order.date}</div>
      <div>관람극장: {order.theater} / 관람인원: {order.viewer_count}인</div>
      <div>결제상태: {order.payment_status} / 주문상태: {order.order_status}</div>
      <div>주문일: {order.order_date}</div>
    </Container>
  );
}
