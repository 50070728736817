import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../AuthContext';

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: #fff;
`;

const FormWrapper = styled.div`
  background-color: white;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

const Title = styled.h1`
  font-size: 1.8rem;
  margin: 20px 0;
  text-align: left;
  border-bottom: 1px solid #DDDDDD;
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  color: #000;
  text-align: left;
  margin-bottom: 20px;
`;

const SubSectionTitle = styled.h2`
  font-size: 1rem;
  margin: 20px 0;
  text-align: left;
  border-bottom: none;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 2px solid #CC9933;
  margin-bottom: 20px;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const LabelField = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const InputField = styled.div`
  margin-left: 20px;
  flex: 2;
  display: flex;
  align-items: center;
  height: 100%;
`;

const RedStar = styled.span`
  color: red;
  margin-right: 5px;
`;

const Label = styled.label`
  font-weight: bold;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 200px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: left;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  margin-top: 20px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ThinDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  margin-bottom: 10px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 40px;
  background-color: #DDDDDD;
  margin: 0 10px;
`;

export default function MyPageUser() {
  const [personalInfo, setPersonalInfo] = useState({
    name: '',
    phone_number: '',
    email: '',
    password: '',
  });

  const [passwordChange, setPasswordChange] = useState({
    password: '',
    new_password: '',
    confirm_new_password: '',
  });

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/user/user/login');
    } else {
      fetch('/request/getUserInfo')
        .then((res) => res.json())
        .then((data) => {
          setPersonalInfo({
            name: data.name || '',
            phone_number: data.phone_number || '',
            email: data.email || '',
            password: '',
          });
        })
        .catch((error) => {
          console.error('개인정보 조회 중 오류 발생:', error);
        });
    }
  }, [user, navigate]);

  const handlePersonalInfoChange = (e) => {
    const { name, value } = e.target;
    setPersonalInfo({
      ...personalInfo,
      [name]: value,
    });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordChange({
      ...passwordChange,
      [name]: value,
    });
  };

  const handlePersonalInfoSubmit = (e) => {
    e.preventDefault();
    fetch('/request/updateUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...personalInfo, new_password: '', confirm_new_password: '' }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('정보가 성공적으로 업데이트되었습니다.');
        } else {
          alert('정보 업데이트에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.error('정보 업데이트 중 오류 발생:', error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      });
  };

  const handlePasswordChangeSubmit = (e) => {
    e.preventDefault();
    fetch('/request/updateUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...passwordChange, name: personalInfo.name, phone_number: personalInfo.phone_number, email: personalInfo.email }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('비밀번호가 성공적으로 변경되었습니다.');
          setPasswordChange({
            password: '',
            new_password: '',
            confirm_new_password: '',
          });
        } else {
          alert('비밀번호 변경에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.error('비밀번호 변경 중 오류 발생:', error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      });
  };

  const handleDeleteAccountSubmit = (e) => {
    e.preventDefault();
    fetch('/request/DeleteProfile', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ password: personalInfo.password }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert('계정이 성공적으로 삭제되었습니다.');
          navigate('/');
        } else {
          alert('계정 삭제에 실패했습니다.');
        }
      })
      .catch((error) => {
        console.error('계정 삭제 중 오류 발생:', error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      });
  };

  return (
    <Container>
      <FormWrapper>
        <Title>개인정보관리</Title>
        <SubTitle><RedStar>*</RedStar> 체크된 항목은 필수 입력 사항입니다</SubTitle>
        <Divider />
        <form onSubmit={handlePersonalInfoSubmit}>
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>이름</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="text"
                name="name"
                value={personalInfo.name}
                onChange={handlePersonalInfoChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>비밀번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="password"
                name="password"
                value={personalInfo.password}
                onChange={handlePersonalInfoChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>휴대폰번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="text"
                name="phone_number"
                value={personalInfo.phone_number}
                onChange={handlePersonalInfoChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <Label>이메일</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="email"
                name="email"
                value={personalInfo.email}
                onChange={handlePersonalInfoChange}
              />
            </InputField>
          </FieldContainer>
          <ButtonContainer>
            <Button type="submit">확인</Button>
          </ButtonContainer>
        </form>
      </FormWrapper>

      <FormWrapper>
        <SubSectionTitle>비밀번호변경</SubSectionTitle>
        <Divider />
        <form onSubmit={handlePasswordChangeSubmit}>
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>현재비밀번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="password"
                name="password"
                value={passwordChange.password}
                onChange={handlePasswordChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>새비밀번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="password"
                name="new_password"
                value={passwordChange.new_password}
                onChange={handlePasswordChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ThinDivider />
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>새비밀번호확인</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="password"
                name="confirm_new_password"
                value={passwordChange.confirm_new_password}
                onChange={handlePasswordChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ButtonContainer>
            <Button type="submit">확인</Button>
          </ButtonContainer>
        </form>
      </FormWrapper>

      <FormWrapper>
        <SubSectionTitle>회원탈퇴</SubSectionTitle>
        <Divider />
        <form onSubmit={handleDeleteAccountSubmit}>
          <FieldContainer>
            <LabelField>
              <RedStar>*</RedStar>
              <Label>비밀번호</Label>
            </LabelField>
            <VerticalDivider />
            <InputField>
              <Input
                type="password"
                name="password"
                value={personalInfo.password}
                onChange={handlePersonalInfoChange}
                required
              />
            </InputField>
          </FieldContainer>
          <ButtonContainer>
            <Button type="submit">확인</Button>
          </ButtonContainer>
        </form>
      </FormWrapper>
    </Container>
  );
}
