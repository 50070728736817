import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';

const Container = styled.div`
  background-color: white;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;



const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const QuillWrapper = styled.div`
  margin: 1rem 0;
  .ql-editor {
    min-height: 200px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 2rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &.submit {
    background-color: #007bff;
    color: white;
  }

  &.cancel {
    background-color: #ccc;
  }
`;

const WriteEditorLite = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { boardType } = location.state || {};
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [date] = useState(new Date().toISOString().split('T')[0]); // 현재 날짜를 기본값으로 설정

  useEffect(() => {
    console.log('Board Type:', boardType); // 디버그용
  }, [boardType]);

  if (!user) {
    return <div>로그인이 필요합니다.</div>;
  }

  if (!boardType) {
    alert('잘못된 게시판 유형입니다.');
    return null;
  }

  let postUrl = '';

  if (boardType === '3') {
    postUrl = '/request/addPurchase';
  } else if (boardType === '17') {
    postUrl = '/request/addOneToOne';
  } else {
    alert('잘못된 게시판 유형입니다.');
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && content) {
      const postData = {
        user_id :user.phone_number,
        views: 0, // 기본값 설정
        title,
        content,
        author: user.name,
        created_at: date
      };

      try {
        const response = await fetch(postUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData),
          credentials: 'include'
        });

        if (response.ok) {
          navigate(`/cscenter/board/${boardType}`);
        } else {
          alert('글 작성에 실패했습니다.');
        }
      } catch (error) {
        console.error('Error posting data:', error);
        alert('글 작성 중 오류가 발생했습니다.');
      }
    } else {
      alert('제목과 내용은 필수로 입력해야 합니다.');
    }
  };

  const handleCancel = () => {
    navigate(`/cscenter/board/${boardType}`);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="제목을 입력하세요."
        />

        <QuillWrapper>
          <ReactQuill
            value={content}
            onChange={setContent}
            placeholder="내용을 입력하세요."
          />
        </QuillWrapper>

        <ButtonRow>
          <Button type="submit" className="submit">확인</Button>
          <Button type="button" className="cancel" onClick={handleCancel}>취소</Button>
        </ButtonRow>
      </Form>
    </Container>
  );
};

export default WriteEditorLite;
