import React, { useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

const PopupWarpper = styled.div`
  display: ${props => props.visible ? "block" : "none"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const PopupInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

const CloseButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
`;

const DontShowLink = styled.span`
  cursor: pointer;
  color: #007bff;
  text-decoration: underline;
  font-size: 14px;
`;

const Popup = ({ visible, setvisible, imageUrl, imgWidth, imgHeight, mImgWidth, mImgHeight }) => {
  const isMobile = window.innerWidth <= 423;
  const currentWidth = isMobile ? mImgWidth : imgWidth;
  const currentHeight = isMobile ? mImgHeight : imgHeight;

  useEffect(() => {
    const dontShowPopup = Cookies.get("dontShowPopup");
    if (dontShowPopup) {
      setvisible(false);
    }
  }, [setvisible]);

  const handleDontShow = () => {
    Cookies.set("dontShowPopup", 'true', { expires: 1 });
    setvisible(false);
  };

  return (
    <PopupWarpper visible={visible}>
      <PopupInner>
        <img 
          src={imageUrl} 
          alt="popup" 
          style={{ 
            width: currentWidth + 'px', 
            height: currentHeight + 'px', 
            maxWidth: "100%", 
            maxHeight: "100%" 
          }} 
        />
        <FooterWrapper>
          <DontShowLink onClick={handleDontShow}>오늘 하루 열지않기</DontShowLink>
          <CloseButton onClick={() => setvisible(false)}>닫기</CloseButton>
        </FooterWrapper>
      </PopupInner>
    </PopupWarpper>
  );
}

export default Popup;
