import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MovieDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;

  /* 768px 이하에서 높이와 크기를 조정 */
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }

  /* 600px 이하에서 더 조정 */
  @media (max-width: 600px) {
    width: 100%;
    padding: 10px;
  }

  /* 468px 이하에서 수직 레이아웃으로 변경 */
  @media (max-width: 468px) {
    flex-direction: column; /* 수직 레이아웃으로 변경 */
    align-items: center; /* 중앙 정렬 */
  }
`;

const Poster = styled.img`
  width: 30%;
  height: auto;
  object-fit: cover;

  /* 468px 이하에서 포스터 크기 조정 */
  @media (max-width: 468px) {
    width: 80%; /* 포스터를 전체 너비의 80%로 설정 */
    margin-bottom: 10px; /* 포스터 아래에 마진 추가 */
  }
`;

const Details = styled.div`
  padding: 20px;
  width: 70%;
  box-sizing: border-box;

  /* 768px 이하에서 텍스트 크기 조정 */
  @media (max-width: 768px) {
    padding: 15px;
  }

  /* 600px 이하에서 텍스트 크기 더 조정 */
  @media (max-width: 600px) {
    padding: 10px;
  }

  /* 468px 이하에서 텍스트 크기 줄임 */
  @media (max-width: 468px) {
    padding: 10px;
    width: 100%; /* 텍스트 영역 전체 너비 사용 */
  }
`;

const Title = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;

  /* 768px 이하에서 제목 크기 조정 */
  @media (max-width: 768px) {
    font-size: 1.8em;
  }

  /* 600px 이하에서 제목 크기 더 조정 */
  @media (max-width: 600px) {
    font-size: 1.5em;
  }

  /* 468px 이하에서 제목 크기 더 조정 */
  @media (max-width: 468px) {
    font-size: 1.3em;
  }
`;

const Info = styled.p`
  font-size: 1em;
  margin: 5px 0;

  /* 768px 이하에서 정보 텍스트 크기 조정 */
  @media (max-width: 768px) {
    font-size: 0.95em;
  }

  /* 600px 이하에서 정보 텍스트 크기 더 조정 */
  @media (max-width: 600px) {
    font-size: 0.9em;
  }

  /* 468px 이하에서 정보 텍스트 크기 더 조정 */
  @media (max-width: 468px) {
    font-size: 0.8em;
  }
`;

const SynopsisContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;

  /* 768px 이하에서 패딩 조정 */
  @media (max-width: 768px) {
    padding: 15px;
  }

  /* 600px 이하에서 패딩 더 조정 */
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const SynopsisTitle = styled.h3`
  font-size: 1.5em;
  margin-bottom: 10px;

  /* 768px 이하에서 시놉시스 제목 크기 조정 */
  @media (max-width: 768px) {
    font-size: 1.4em;
  }

  /* 600px 이하에서 시놉시스 제목 크기 더 조정 */
  @media (max-width: 600px) {
    font-size: 1.2em;
  }

  /* 468px 이하에서 시놉시스 제목 크기 더 조정 */
  @media (max-width: 468px) {
    font-size: 1em;
  }
`;

const SynopsisText = styled.p`
  font-size: 1em;

  /* 768px 이하에서 시놉시스 텍스트 크기 조정 */
  @media (max-width: 768px) {
    font-size: 0.95em;
  }

  /* 600px 이하에서 시놉시스 텍스트 크기 더 조정 */
  @media (max-width: 600px) {
    font-size: 0.9em;
  }

  /* 468px 이하에서 시놉시스 텍스트 크기 더 조정 */
  @media (max-width: 468px) {
    font-size: 0.8em;
  }
`;

const BackButton = styled.button`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FFC107;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  /* 768px 이하에서 버튼 크기 조정 */
  @media (max-width: 768px) {
    font-size: 0.95em;
    padding: 9px 18px;
  }

  /* 600px 이하에서 버튼 크기 더 조정 */
  @media (max-width: 600px) {
    font-size: 0.9em;
    padding: 8px 16px;
  }
`;

const possibleExtensions = ["jpg", "jpeg", "png", "gif", "webp"];

const DetailsMovieInfo = () => {
  const { movie_code } = useParams();
  const [movie, setMovie] = useState(null);
  const [imageSrc, setImageSrc] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.state?.type || "current"; // 'current' or 'upcoming'

  useEffect(() => {
    const fetchMovieDetails = async () => {
      try {
        const response = await fetch(
          `/request/${
            type === "current" ? "getCurrentMovie" : "getUpcomingMovie"
          }/${movie_code}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new TypeError("Received non-JSON response");
        }
        const data = await response.json();
        console.log("Fetched movie data:", data);
        setMovie(data);
        fetchImage(data.poster);
      } catch (error) {
        console.error("Error fetching movie details:", error);
      }
    };

    fetchMovieDetails();
  }, [movie_code, type]);

  const fetchImage = async (posterPath) => {
    for (let ext of possibleExtensions) {
      const imagePath = posterPath;
      try {
        const response = await fetch(imagePath, { method: "HEAD" });
        if (response.ok) {
          setImageSrc(imagePath);
          break;
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    }
  };

  const handleBackClick = () => {
    const today = new Date();
    const releaseDate = new Date(movie.release_date);

    if (location.state && location.state.from) {
      // 이전 페이지가 있다면 그 경로로 돌아감
      navigate(location.state.from);
    } else if (releaseDate <= today) {
      // 개봉일이 오늘이나 과거라면 현재 상영작
      navigate("/movie/info/index?type=ing");
    } else {
      // 개봉일이 미래라면 상영 예정작
      navigate("/movie/info/index?type=prev");
    }
  };

  if (!movie) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container>
      <MovieDetailsContainer>
        {imageSrc && <Poster src={imageSrc} alt={movie.title} />}
        <Details>
          <Title>{movie.title}</Title>
          <Info>
            <strong>감독:</strong> {movie.director}
          </Info>
          <Info>
            <strong>주연:</strong> {movie.lead_actor}
          </Info>
          <Info>
            <strong>개봉일:</strong>{" "}
            {new Date(movie.release_date).toLocaleDateString()}
          </Info>
        </Details>
      </MovieDetailsContainer>
      <SynopsisContainer>
        <SynopsisTitle>시놉시스</SynopsisTitle>
        <SynopsisText>{movie.synopsis}</SynopsisText>
      </SynopsisContainer>
      <BackButton onClick={handleBackClick}>목록</BackButton>
    </Container>
  );
};

export default DetailsMovieInfo;
