import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import { getSessionFunc } from "../../api/func/userFunc";
const HomeContext = createContext();

const HomeProvider = ({ children }) => {
  const [lotGridRows, setLotGridRows] = useState("");
  const { isLoggedIn, setIsLoggedIn, setUser } = useAuth();

  useEffect(() => {
    // console.log("HP");
    const storedUser = JSON.parse(sessionStorage.getItem("user"));

    // id로 session 데이터 가져옴
    const getSession = async (phone_number) => {
      const returnSession = await getSessionFunc(phone_number);
      return returnSession;
    };

    //로그인 상태 판별
    const isLoginFunc = async (storedUser) => {
      // console.log(`=== start is login ===`);
      // 세션 스토리지의 id를 가져온다.
      if (
        storedUser !== null &&
        (storedUser.phone_number !== null ||
          storedUser.phone_number !== undefined)
      ) {
        try {
          // console.log("session 값이 있음");
          // console.log(`storedUser.phone_number : ${storedUser.phone_number}`);
          const returnSession = await getSession(storedUser.phone_number);
          // console.log(returnSession);
          // console.log(JSON.stringify(returnSession));
          // console.log(`code : ${returnSession.code} `);
          if (returnSession.code == 200 && returnSession.result !== 0) {
            // 서버 세션이 남아있을 경우
            // console.log("서버 session true");
            setIsLoggedIn(true);
            setUser(storedUser);
          } else {
            // 서버 Redis에 세션이 없을 경우
            // console.log("서버 session false");
            setIsLoggedIn(false);
            setUser(null);
            sessionStorage.removeItem("user");
          }
        } catch (e) {
          console.log("로그인 판별 에러 : e");
        }
      } else {
        // 세션 스토리지 아이디가 없을 경우
        // console.log("session false");
        setIsLoggedIn(false);
        setUser(null);
      }
      // console.log(`=== end is login ===`);
    };
    isLoginFunc(storedUser);
  }, [isLoggedIn]);

  return (
    <HomeContext.Provider value={{ lotGridRows, setLotGridRows }}>
      {children}
    </HomeContext.Provider>
  );
};

const HomeConsumer = ({ children }) => {
  const value = useContext(HomeContext);

  return <div>Lot grid rows: {value.lotGridRows}</div>;
};

export { HomeProvider, HomeConsumer, HomeContext };
