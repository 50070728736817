import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import MovieScheduleFilter from "../MovieScheduleFilter";
import { useSetRecoilState } from "recoil";
import { couponCodeAtom, couponPriceAtom } from "../../atoms/atomSample";
import Cookies from "js-cookie";
import { getSessionFunc } from "../../api/func/userFunc";
const couponTypes = {
  type_g: "영화예매권A",
  type_l: "씨네1인권",
  type_m: "영화VIP",
  type_n: "2인중1인권",
  type_h: "영화예매권B",
  type_j: "영화예매권",
  type_d: "영화예매할인권(5000P)",
  type_k: "동반할인권",
};

const Container = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const CouponSection = styled.div`
  border: 1px solid #ddd;
  padding: 0 20px 20px;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  font-size: 0.9rem;
  margin-bottom: 10px;
`;

const Instruction = styled.p`
  font-size: 0.8rem;
  color: #555;
`;

const RegisterButton = styled.button`
  background-color: #ffc107;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-bottom: 20px;
`;

const CouponTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f4f4f4;
  font-size: 0.9rem;
  width: ${({ width }) => width || "auto"};
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  font-size: 0.8rem;
  color: #555;
  text-decoration: ${({ isExpired, isCouponStatusFalse }) =>
    isExpired || isCouponStatusFalse ? "line-through" : "none"};
  color: ${({ isExpired, isCouponStatusFalse }) =>
    isExpired || isCouponStatusFalse ? "#aaa" : "inherit"};
  position: relative;

  &:hover .delete-btn {
    display: inline-block;
  }
`;

const DeleteButton = styled.button`
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: red;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    color: darkred;
  }
`;

const ApplyButton = styled.button`
  background-color: #ff5733;
  color: white;
  padding: 5px 5px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  /* 768px 이하일 때 적용으로 변경 */
  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 425px) {
    font-size: 0.8em;
  }
`;
const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const SlideContainer = styled.div`
  position: fixed;
  top: 30%;
  left: 0;
  width: 50%;
  background-color: #333;
  color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
  animation: ${({ isVisible }) => (isVisible ? slideDown : "none")} 0.5s
    forwards;
  height: 30%; /* 상단에서 30% */
  display: flex;
  flex-direction: column;
  z-index: 3000;
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  overflow-y: auto; /* 테이블 내에서 스크롤 가능 */
  background-color: white;
  color: black;
  padding: 10px;
`;

const TableContainer = styled.table`
  width: 100%;
  margin-top: 10px;
  border-collapse: collapse;
`;
const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;

  &:hover {
    color: #ff5733; /* 호버 시 버튼 색상 변경 */
  }
`;
let bookingPopup = null; // 팝업 객체를 전역 변수로 선언
let applyPopup = null;

export default function MovieTicketing() {
  const [isSlideVisible, setIsSlideVisible] = useState(false); // 슬라이드 표시 상태
  const [applyCurrentCoupon, setApplyCurrentCoupon] = useState(false);
  const [tableData, setTableData] = useState([]); // 테이블에 표시할 데이터
  const [inputBuffer, setInputBuffer] = useState(""); // 키보드 입력 버퍼
  const inputRef = useRef(null);
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [coupons, setCoupons] = useState([]);
  const [showScheduleFilter, setShowScheduleFilter] = useState(false);
  const [couponCodeCheck, setCouponCodeCheck] = useState(null);
  const [showInfoColumn, setShowInfoColumn] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const { isLoggedIn, setIsLoggedIn, setUser } = useAuth();

  useEffect(() => {
    // console.log("HP");
    const storedUser = JSON.parse(sessionStorage.getItem("user"));

    // id로 session 데이터 가져옴
    const getSession = async (phone_number) => {
      const returnSession = await getSessionFunc(phone_number);
      return returnSession;
    };

    //로그인 상태 판별
    const isLoginFunc = async (storedUser) => {
      // console.log(`=== start is login 2===`);
      // 세션 스토리지의 id를 가져온다.
      if (
        storedUser !== null &&
        (storedUser.phone_number !== null ||
          storedUser.phone_number !== undefined)
      ) {
        try {
          // console.log("session 값이 있음");
          // console.log(`storedUser.phone_number : ${storedUser.phone_number}`);
          const returnSession = await getSession(storedUser.phone_number);
          // console.log(returnSession);
          // console.log(JSON.stringify(returnSession));
          // console.log(`code : ${returnSession.code} `);
          if (returnSession.code == 200 && returnSession.result !== 0) {
            // 서버 세션이 남아있을 경우
            // console.log("서버 session true");
            setIsLoggedIn(true);
            setUser(storedUser);
          } else {
            // 서버 Redis에 세션이 없을 경우
            // console.log("서버 session false");
            setIsLoggedIn(false);
            setUser(null);
            sessionStorage.removeItem("user");
          }
        } catch (e) {
          console.log("로그인 판별 에러 : e");
        }
      } else {
        // 세션 스토리지 아이디가 없을 경우
        // console.log("session false");
        setIsLoggedIn(false);
        setUser(null);
      }
      // console.log(`=== end is login ===`);
    };
    isLoginFunc(storedUser);
  }, [isLoggedIn]);

  // 컴포넌트가 마운트되면, 키보드 이벤트 리스너를 추가
  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key.toLowerCase();

      if (key === "backspace") {
        setInputBuffer((prev) => prev.slice(0, -1));
      } else if (key === "enter") {
        if (inputBuffer === "log") {
          setIsSlideVisible(true);
          fetchTableData();
        } else if (inputBuffer === "info") {
          fetchInfoData();
          setShowInfoColumn(true);
        }
        setInputBuffer("");
      } else if (/^[a-z0-9]$/i.test(key)) {
        setInputBuffer((prev) => prev + key);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputBuffer]);

  const fetchInfoData = async () => {
    try {
      const response = await fetch("/request/couponTableData", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("데이터를 가져오는데 실패했습니다.");
      }

      const data = await response.json();
      setInfoData(data);
    } catch (error) {
      console.error("Error fetching info data:", error);
    }
  };
  const setCouponCode = useSetRecoilState(couponCodeAtom);
  const setCouponPrice = useSetRecoilState(couponPriceAtom);

  useEffect(() => {
    if (!user) {
      navigate("/user/user/login");
    } else {
      fetchCoupons();
      const intervalId = setInterval(() => {
        const couponApplied = Cookies.get("couponApplied");
        if (couponApplied === "true") {
          setShowScheduleFilter(true);
          setCouponCodeCheck(Cookies.get("couponCode"));
          clearInterval(intervalId);
        }
      }, 500);

      return () => clearInterval(intervalId);
    }
  }, [user, navigate, couponCodeCheck]);
  const clearCouponCookie = () => {
    const couponApplied = Cookies.get("couponApplied");
    if (couponApplied) {
      Cookies.remove("couponApplied", { path: "/" });
      setShowScheduleFilter(false);
      setCouponCodeCheck(null);
    }
  };
  useEffect(() => {
    return () => {
      clearCouponCookie();
    };
  }, [location]);

  const handleCouponRemoval = () => {
    Cookies.remove("couponApplied", { path: "/" });
    alert("상영관 및 좌석을 아래 화면에서 선택하세요");
    setCouponCodeCheck(null);
    setShowScheduleFilter(false);
  };

  const fetchCoupons = async () => {
    try {
      const response = await fetch("/request/getUserCoupons", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("쿠폰 가져오기 실패");
      }

      const data = await response.json();
      setCoupons(data);
    } catch (error) {
      console.error("Error fetching coupons:", error);
    }
  };

  const deleteCoupon = (coupon_code) => {
    fetch("/request/deleteCouponCert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ coupon_code }),
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          setCoupons((prevCoupons) =>
            prevCoupons.filter((coupon) => coupon.coupon_code !== coupon_code)
          );
        } else {
          console.error("Failed to delete coupon");
        }
      })
      .catch((error) => {
        console.error("Error deleting coupon:", error);
      });
  };

  const openCouponRegisterPopup = () => {
    bookingPopup = window.open(
      "/mypage/coupon/registercoupon?issetcoupon=false",
      "Coupon Register",
      "width=600,height=600,top=100,left=100"
    );

    if (bookingPopup) {
      // 팝업이 성공적으로 열렸을 때
      bookingPopup.focus(); // 팝업에 포커스 맞추기
      window.addEventListener("message", handleBookingMessage);
    } else {
      alert("팝업이 차단되었습니다. 브라우저 설정을 확인해주세요.");
    }
  };

  // 메시지 수신 핸들러
  const handleBookingMessage = (event, bookingPopup) => {
    if (event.origin !== window.location.origin) return; // 동일 출처 정책 확인
    if (event.data === "bookingPopup") {
      fetchCoupons();
    }
  };
  const handleApplyMessage = (event, applyPopup) => {
    if (event.origin !== window.location.origin) return; // 동일 출처 정책 확인
    if (event.data === "applyPopup") {
      setShowScheduleFilter(true);
    }
  };
  // 컴포넌트 언마운트 시 메시지 이벤트 리스너 제거
  useEffect(() => {
    return () => {
      window.removeEventListener("bookingPopup", handleBookingMessage);
      window.removeEventListener("applyPopup", handleApplyMessage);
    };
  }, []);

  const checkProductRecommendations = async (coupon) => {
    setApplyCurrentCoupon(coupon);
    let couponCode = coupon.coupon_code;
    try {
      const response = await fetch("/product/getProductRecommendations", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("추천 상품 가져오기 실패");
      }

      const products = await response.json();
      if (products.length > 0) {
        openProductRecommendationPopup(couponCode);
      } else {
        await handleCouponApplied(couponCode);
      }
    } catch (error) {
      console.error("Error fetching product recommendations:", error);
    }
  };

  const openProductRecommendationPopup = (couponCode) => {
    applyPopup = window.open(
      `/mypage/coupon/productRecommendation?couponCode=${couponCode}`,
      "Product Recommendation",
      "width=600,height=600,top=100,left=100"
    );
    if (applyPopup) {
      // 팝업이 성공적으로 열렸을 때
      applyPopup.focus(); // 팝업에 포커스 맞추기
      window.addEventListener("message", handleApplyMessage);
    } else {
      alert("팝업이 차단되었습니다. 브라우저 설정을 확인해주세요.");
    }
  };

  const handleCouponApplied = async (couponCode) => {
    try {
      const response = await fetch("/request/applyCoupon", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ coupon_code: couponCode }),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("쿠폰 적용 실패");
      }

      const data = await response.json();

      const couponPrice = data.coupon_price || 0;

      setCouponCode(couponCode);
      setCouponPrice(couponPrice);

      setCouponCodeCheck(couponCode);

      await fetchCoupons();
      alert("쿠폰이 성공적으로 적용되었습니다.");
    } catch (error) {
      console.error("Error applying coupon:", error);
    }
  };

  if (!user) {
    return null;
  }

  // 쿠폰 코드 데이터 가져오기
  const fetchTableData = async () => {
    try {
      // 여기에 실제 데이터를 조회하는 API 요청을 추가합니다
      const response = await fetch("/request/couponTableData");
      const data = await response.json();
      setTableData(data); // 가져온 데이터를 상태에 저장
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  return (
    <Container>
      {isSlideVisible && (
        <SlideContainer isVisible={isSlideVisible}>
          <h2>쿠폰 테이블</h2>
          <TableWrapper>
            <TableContainer>
              <thead>
                <tr>
                  <TableHeader>쿠폰 코드</TableHeader>
                  <TableHeader>설명</TableHeader>
                  <TableHeader>유효기간</TableHeader>
                  {showInfoColumn && (
                    <>
                      <TableData>데이터</TableData>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 ? (
                  tableData.map((item, index) => (
                    <tr key={index}>
                      <TableData>{item.coupon_code}</TableData>
                      <TableData>{item.coupon_status}</TableData>
                      <TableData>{item.created_at}</TableData>
                      {showInfoColumn && (
                        <TableData>{JSON.stringify(item)}</TableData>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <TableData colSpan="3">데이터가 없습니다.</TableData>
                  </tr>
                )}
              </tbody>
            </TableContainer>
          </TableWrapper>
          <CloseButton onClick={() => setIsSlideVisible(false)}>
            닫기
          </CloseButton>
        </SlideContainer>
      )}
      <Instruction>
        * 쿠폰 등록 전 반드시 희망 영화관에서 상영하는지 확인부탁드립니다.
        <br />
        확인방법: 영화예매하기에서 확인하실 수 있습니다.
      </Instruction>
      <CouponSection>
        <Title>등록된 쿠폰</Title>
        <RegisterButton onClick={openCouponRegisterPopup}>
          예매권 등록하기
        </RegisterButton>
        <CouponTable>
          <thead>
            <tr>
              <TableHeader width="20%">인증번호</TableHeader>
              <TableHeader width="40%">쿠폰이름</TableHeader>
              <TableHeader width="15%">등록기간</TableHeader>
              <TableHeader width="15%">유효기간</TableHeader>
              <TableHeader width="10%">적용</TableHeader>
            </tr>
          </thead>
          <tbody>
            {coupons.length === 0 && (
              <tr>
                <TableData colSpan="5">쿠폰이 없습니다.</TableData>
              </tr>
            )}
            {coupons.map((coupon) => {
              // === STEP 1: 날짜 및 조건 정의 ===
              const isPeriodLimit = coupon.period_limit === "true"; // period_limit이 true인지 확인
              const expirationDate = new Date(coupon.reg_exp_period_e); // 유효기간 종료일
              const regDate = new Date(coupon.reg_date); // 등록일 가져오기
              const regExpPeriod = coupon.reg_exp_period || 0; // 등록일로부터 유효 기간 (일)

              const currentDate = new Date(); // 현재 날짜 가져오기
              const regDatePlusPeriod = new Date(regDate); // 등록일 + reg_exp_period 계산
              regDatePlusPeriod.setDate(
                regDatePlusPeriod.getDate() + regExpPeriod
              );

              // === STEP 2: 남은 일수 계산 ===
              let daysRemaining = 0;

              if (isPeriodLimit) {
                // period_limit이 true인 경우, 두 조건 중 더 이른 날짜를 기준으로 남은 일수를 계산
                const effectiveExpirationDate =
                  expirationDate < regDatePlusPeriod
                    ? expirationDate
                    : regDatePlusPeriod;
                daysRemaining = Math.max(
                  Math.floor(
                    (effectiveExpirationDate - currentDate) /
                      (1000 * 60 * 60 * 24)
                  ),
                  0
                );
              }

              const isExpired = isPeriodLimit && daysRemaining === 0; // 만료 여부
              const isCouponStatusFalse = coupon.coupon_status === "사용완료"; // 쿠폰 상태가 '사용완료'인지 확인
              const isApplied = coupon.coupon_code === couponCodeCheck; // 현재 적용된 쿠폰인지 확인

              // === STEP 2: 테이블 행 구성 ===
              return (
                <tr key={coupon.coupon_code}>
                  {/* === STEP 2-1: 쿠폰 코드 열 === */}
                  <TableData
                    isExpired={isExpired}
                    isCouponStatusFalse={isCouponStatusFalse}
                  >
                    {coupon.coupon_code}
                  </TableData>

                  {/* === STEP 2-2: 쿠폰 이름 열 === */}
                  <TableData
                    isExpired={isExpired}
                    isCouponStatusFalse={isCouponStatusFalse}
                  >
                    {couponTypes[coupon.coupon_type] || coupon.coupon_type}
                    {(isExpired ||
                      isCouponStatusFalse ||
                      daysRemaining <= 0) && (
                      <DeleteButton
                        className="delete-btn"
                        onClick={() => deleteCoupon(coupon.coupon_code)}
                      >
                        X
                      </DeleteButton>
                    )}
                  </TableData>

                  {/* === STEP 2-3: 유효 기간 열 === */}
                  <TableData
                    isExpired={isExpired}
                    isCouponStatusFalse={isCouponStatusFalse}
                  >
                    {expirationDate.toLocaleDateString("ko-KR", {    year: "numeric",    month: "2-digit",    day: "2-digit",  }).replace(/\.$/, "")}
                    {isExpired && " (기간만료)"}
                  </TableData>

                  {/* === STEP 2-4: 남은 일수 열 === */}
                  <TableData
                    isExpired={isExpired}
                    isCouponStatusFalse={isCouponStatusFalse}
                  >
                    {isPeriodLimit
                      ? daysRemaining > 0
                        ? `${daysRemaining}일 남음` // 남은 일수 표시
                        : "사용 기간 종료" // 남은 일수가 없으면 종료 메시지 표시
                      : "-"}{" "}
                    {/* isPeriodLimit이 false일 경우 빈값 표시 */}
                  </TableData>

                  {/* === STEP 2-5: 적용 버튼 열 === */}
                  <TableData>
                    {isApplied ? (
                      // 이미 적용된 쿠폰인 경우
                      <ApplyButton onClick={handleCouponRemoval}>
                        적용됨
                      </ApplyButton>
                    ) : (
                      // 만료되거나 사용 완료된 경우 적용 버튼 비활성화
                      <ApplyButton
                        onClick={() => checkProductRecommendations(coupon)}
                        disabled={
                          isExpired || isCouponStatusFalse || daysRemaining <= 0
                        }
                      >
                        적용하기
                      </ApplyButton>
                    )}
                    <input hidden value={coupon.max_order_cnt}></input>
                  </TableData>
                </tr>
              );
            })}
          </tbody>
        </CouponTable>
      </CouponSection>
      {showScheduleFilter && (
        <MovieScheduleFilter
          applyCurrentCoupon={applyCurrentCoupon}
          clearCouponCookie={clearCouponCookie}
          setShowScheduleFilter={setShowScheduleFilter}
        />
      )}
      <Outlet />
    </Container>
  );
}
