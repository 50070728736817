import React, { useState, useEffect } from 'react';

const AppSetupConfig = () => {
  const [config, setConfig] = useState({});
  const [editedConfig, setEditedConfig] = useState({});

 // 환경변수 초기화
 useEffect(() => {
  const initialConfig = {
    REACT_APP_SERVER_DOMAIN: process.env.REACT_APP_SERVER_DOMAIN || '',
    REACT_APP_SERVER_PORT: process.env.REACT_APP_SERVER_PORT || '',
    REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL || '',
    REACT_APP_DB_HOST: process.env.REACT_APP_DB_HOST || '',
    REACT_APP_DB_PORT: process.env.REACT_APP_DB_PORT || '',
    REACT_APP_DB_NAME: process.env.REACT_APP_DB_NAME || '',
    REACT_APP_DB_USER: process.env.REACT_APP_DB_USER || '',
    REACT_APP_DB_PASSWORD: process.env.REACT_APP_DB_PASSWORD || '',
    REACT_APP_THEME: process.env.REACT_APP_THEME || '',
    REACT_APP_FEATURE_FLAG: process.env.REACT_APP_FEATURE_FLAG || '',
  };
  setConfig(initialConfig);
  setEditedConfig(initialConfig);
}, []);

// 입력 변경 핸들러
const handleInputChange = (key, value) => {
  setEditedConfig((prev) => ({
    ...prev,
    [key]: value,
  }));
};

// 저장 버튼 클릭 핸들러
const handleSave = () => {
  console.log('Updated Configuration:', editedConfig);
  alert('Configuration saved!');
  setConfig(editedConfig);
};

  return (
    <div style={{width:'800px', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
      <h2>앱 설정 화면</h2>
      <div style={{ marginBottom: '20px' }}>
        {Object.entries(editedConfig).map(([key, value]) => (
          <div key={key} style={{ marginBottom: '10px' }}>
            <label style={{ display: 'block', fontWeight: 'bold', marginBottom: '5px' }}>{key}</label>
            <input
              type="text"
              value={value}
              onChange={(e) => handleInputChange(key, e.target.value)}
              style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
        ))}
      </div>
      <button
        onClick={handleSave}
        style={{
          padding: '10px 20px',
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
       저장
      </button>
    </div>
  );
};

export default AppSetupConfig;