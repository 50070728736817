import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from "../../../AuthContext"; // AuthContext에서 사용자 정보를 가져옵니다.

const couponTypes = {
  "type_g": "영화예매권A",
  "type_i": "제주도쿠폰",
  "type_l": "씨네1인권",
  "type_m": "영화VIP",
  "type_n": "2인중1인권",
  "type_h": "영화예매권B",
  "type_j": "영화예매권",
  "type_d": "영화예매할인권(5000P)",
  "type_k": "동반할인권"
};

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const Input = styled.input`
  width: calc(100% - 100px);
  padding: 0.5rem;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #FFC107;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const Th = styled.th`
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  background-color: #f4f4f4;
  text-align: left;
`;

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
`;

export default function CouponRegister() {
  const [couponCode, setCouponCode] = useState('');
  const [coupons, setCoupons] = useState([]);
  const { user } = useAuth(); // 사용자 정보를 AuthContext에서 가져옵니다.\

  const handleClose = () => {
    // 부모 창에 팝업 닫기 신호 전송
    if (window.opener) {
      window.opener.postMessage('bookingPopup', window.location.origin);
    }
    window.close();
  };
  const fetchCoupons = async () => {
    try {
      console.log('Fetching user coupons');
      const response = await fetch('/request/getUserCoupons', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('쿠폰 가져오기 실패');
      }

      const data = await response.json();
      setCoupons(data);
    } catch (error) {
      console.error('Error fetching coupons:', error);
    }
  };

  const handleRegister = async () => {
    try {
      // 쿠폰 코드의 공백 제거
      let formattedCouponCode = couponCode.trim();
  
      // 하이픈이 없고, 길이가 12자리인 경우 하이픈을 추가
      if (!formattedCouponCode.includes('-') && formattedCouponCode.length === 12) {
        formattedCouponCode = `${formattedCouponCode.slice(0, 4)}-${formattedCouponCode.slice(4, 8)}-${formattedCouponCode.slice(8)}`;
      }
  
      const response = await fetch('/request/registerCoupon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ coupon_code: formattedCouponCode }),
        credentials: 'include',
      });
      // 응답 본문을 텍스트로 읽어옵니다.
      const responseBody = await response.text();
  
      if (response.ok) {
        // 성공 시
        alert(responseBody); // 서버에서 반환한 메시지를 alert로 표시
        setCouponCode('');
        fetchCoupons(); // 쿠폰 목록을 다시 불러옴
        handleClose(); // 팝업 닫기
      } else {
        // 오류 발생 시 서버에서 반환한 메시지를 alert로 표시
        alert(responseBody);
      }
    } catch (error) {
      if (error.message === 'Failed to fetch') {
        alert('서버에 연결할 수 없습니다. 네트워크를 확인해주세요.');
      } else {
        alert(`${error.message}`);
      }
    }
  };

  // 컴포넌트가 마운트될 때 쿠폰 목록을 가져옵니다.
  useEffect(() => {
    fetchCoupons();
  }, []);

  return (
    <Container>
      <Title>인증번호 등록 및 조회</Title>
      <div>
        <Input
          type="text"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          placeholder="쿠폰번호"
        />
        <Button onClick={handleRegister}>등록</Button>
      </div>
      <Table>
        <thead>
          <tr>
            <Th>쿠폰번호</Th>
            <Th>쿠폰이름</Th>
            <Th>유효기간</Th>
            <Th>사용횟수</Th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon, index) => (
            <tr key={index}>
              <Td>{coupon.coupon_code}</Td>
              <Td>{couponTypes[coupon.coupon_type] || coupon.coupon_type}</Td>
              <Td>{coupon.reg_exp_period_e ? new Date(coupon.reg_exp_period_e).toLocaleDateString() : 'N/A'}</Td>
              <Td>{coupon.used_cnt || '-'}</Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}
