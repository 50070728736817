import React, { useState, createContext, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { delSessionFunc } from "../api/func/userFunc";

export const AuthContext = createContext({
  isLoggedIn: false,
  user: null,
  login: () => {},
  logout: () => {},
  setUser: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  let navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const login = async (userData) => {
    // console.log(`## login start ##`);
    sessionStorage.setItem("user", JSON.stringify(userData));
    setIsLoggedIn(true);
    setUser(userData);
    // console.log(`## login end ##`);
  };

  const logout = async () => {
    // console.log(`## logout start ##`);
    const storedUser = await JSON.parse(sessionStorage.getItem("user"));
    await delSessionFunc(storedUser.phone_number);
    sessionStorage.removeItem("user");

    setIsLoggedIn(false);
    setUser(null);
    // console.log(`## logout end ##`);
    navigate("/"); // 로그아웃 후 로그인 페이지로 리디렉션
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, login, logout, setIsLoggedIn, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};
